import { BASE_URL } from "constants/Endpoints";
import axios from "../core/axios";

export const CompanyAPI = {
    
    getAutoReplyMessage : async({token,companyId}) => {
        return await axios.get(`${BASE_URL}/company/${companyId}/autoreply/message`,{
            headers : {
                'Authorization': 'Bearer ' + token
            }
        });
    },
    getAutoReplyTimings : async({token,companyId}) => {
        return await axios.get(`${BASE_URL}/company/${companyId}/autoreply/timing`,{
            headers : {
                'Authorization': 'Bearer ' + token
            }
        });
    },
    updatAutoReplyMessage : async({token,payload}) => {
        return await axios.post(`${BASE_URL}/company/autoreply/message`,JSON.stringify(payload),{
            headers : {
                'Authorization': 'Bearer ' + token,
                "Content-Type": "application/json"
            }
        });
    },
    updatAutoReplyTiming : async({token,payload}) => {
        return await axios.post(`${BASE_URL}/company/autoreply/timing`,JSON.stringify(payload),{
            headers : {
                'Authorization': 'Bearer ' + token,
                "Content-Type": "application/json"
            }
        });
    },    
    updateAutoReply : async({token, payload}) => {
        return await axios.post(`${BASE_URL}/company/${payload.companyId}/schedule/autoreply/settings`,JSON.stringify(payload),{
            headers : {
                'Authorization': 'Bearer ' + token,
                "Content-Type": "application/json"
            }
        });
    },
    updateCompanyCredit : async({token,payload}) => {
        return await axios.post(`${BASE_URL}/company/update/credit`,JSON.stringify(payload),{
            headers : {
                'Authorization': 'Bearer ' + token,
                "Content-Type": "application/json"
            }
        });
    },
    syncContacts : async({token,payload}) => {
        return await axios.post(`${BASE_URL}/company/google-token/save`,JSON.stringify(payload),{
            headers : {
                'Authorization': 'Bearer ' + token,
                "Content-Type": "application/json"
            }
        });
    },
    updateHipaaSubscription : async({token,subscribed,companyId}) => {
        return await axios.get(`${BASE_URL}/company/${companyId}/features/hipaa?subscribed=${subscribed}`,{
            headers : {
                'Authorization': 'Bearer ' + token,
                "Content-Type": "application/json"
            }
        });
    },
    updateSubscription : async({payload,token,companyId}) => {
        return await axios.post(`${BASE_URL}/company/${companyId}/features/subscribe`,JSON.stringify(payload),{
            headers : {
                'Authorization': 'Bearer ' + token,
                "Content-Type": "application/json"
            }
        });
    },

    getUserCompany : async({token,userId}) => {
        const payload = {
            userId
        }
        return await axios.post(`${BASE_URL}/company/user`,JSON.stringify(payload),{
            headers : {
                'Authorization': 'Bearer ' + token,
                "Content-Type": "application/json"
            }
        });
    },
    updateCompanyBanner : async({token,payload}) => {
        return await axios.post(`${BASE_URL}/company/update-banner`,JSON.stringify(payload),{
            headers : {
                'Authorization': 'Bearer ' + token,
                "Content-Type": "application/json"
            }
        });
    },
    removeNumberFromCompany : async({token,payload}) => {
        return await axios.post(`${BASE_URL}/company/update/phone`,JSON.stringify(payload),{
            headers : {
                'Authorization': 'Bearer ' + token,
                "Content-Type": "application/json"
            }
        });
    },
    createCompanyTag : async({token,payload}) => {
        return await axios.post(`${BASE_URL}/company/create/tag`,JSON.stringify(payload),{
            headers : {
                'Authorization': 'Bearer ' + token,
                "Content-Type": "application/json"
            }
        });
    },
    deleteCompany : async({token,companyId}) => {
        return await axios.delete(`${BASE_URL}/company/${companyId}`,{
            headers : {
                'Authorization': 'Bearer ' + token,
            }
        });
    },
    updateCardknoxKey : async({token,payload}) => {
        return await axios.post(`${BASE_URL}/company/cardknox/update`,JSON.stringify(payload),{
            headers : {
                'Authorization': 'Bearer ' + token,
                "Content-Type": "application/json"
            }
        });
    },
}