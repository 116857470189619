import RightSideBarContainer from "components/CommonUI/RightSideBarContainer";
import { FlexContainer } from "pages/Chat/ChatStyles";
import { SearchOuterWrapper } from "pages/Chat/ChatStyles";
import { AddPhoneontainer } from "pages/Chat/ChatStyles";
import { SearchInput } from "pages/Chat/ChatStyles";
import { NavbarOuterWrapper } from "pages/Chat/ChatStyles";
import { memo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ichatVariablesAction } from "store/IChat/Ichat-variables-slice";
import SearchIcon from "@mui/icons-material/Search";
import { Badge, Button, CircularProgress, Popover, Tooltip, Typography } from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { useState } from "react";
import TeleFilterOptions from "../TeleFilterOptions";
import usePopup from "hooks/use-popup";
import MDBox from "lib/components/MDBox";
import { useCallback } from "react";
import { useEffect } from "react";
import SearchItem from "./SearchItem";
import useDebouncedWatch from "hooks/use-debounce-watch";
import { SearchAPI } from "services/SearchAPI";
import InfiniteScroll from "react-infinite-scroll-component";
import { toast } from "react-toastify";

// const searchData = [
//   {
//     id : 1,
//     title: '9876864549',
//     content : 'something will be here',
//     created_at : "06-20-2023"
//   },
//   {
//     id : 2,
//     title: '9876864549',
//     content : 'something will be here',
//     created_at : "06-20-2023"
//   },
//   {
//     id : 3,
//     title: '9876864549',
//     content : 'something will be here',
//     created_at : "06-20-2023"
//   }
// ]
const TeleGlobalSearch = () => {
  const dispatch = useDispatch();

  const [filterOptions, setFilterOptions] = useState({
    user: "",
    tag: "",
    date: "",
    recipient: undefined
  });

  const token = useSelector(state => state.user.token);
  const currentActivePhone = useSelector(state => state.chat.current_active_phone);
  const userCompany = useSelector(state => state.user.company);
  const searchContainer = useRef();
  const [filterLength,setFilterLength] = useState(0);
  const [searchPattern,setSearchPattern] = useState();
  const [searchData,setSearchData] = useState(undefined);
  const [loading,setLoading] = useState(false);
  const {
    popoverId,
    anchorEl,
    openPopover,
    handleOpenPopup,
    handleClosePopup,
  } = usePopup();

  const onSearchClose = () => {
    dispatch(ichatVariablesAction.updateRightSideBar({ open: false, content: <></> }));
  };

  const handleClear = () => {
    setFilterOptions({
      user: "",
      tag: undefined,
      date: "",
      recipient : undefined,
    })
    setFilterLength(0)
    setSearchData(undefined);
    setSearchPattern('')
  }

  const onFilterApplied = useCallback(() => {
    handleClosePopup();  
    if(searchPattern) {
      fetchSearchResult(0);
    }
  },[filterOptions,searchPattern]);

  useEffect(() => {
    let count = 0;
    if (filterOptions.date) count++;
    if (filterOptions.tag) count++;
    if (filterOptions.user && parseInt(filterOptions.user) > 0) count++;
    if(filterOptions.recipient && filterOptions.recipient?.length === 10) count++;
    setFilterLength(count);
  }, [filterOptions]);

  const buildFilterObj = () => {
    console.log(filterOptions)
    const filters = [
      {
        field : 'message',
        key: 'message',
        operator : "cn",
        value : searchPattern
      },
      {
        field : 'company_id',
        key: 'companyId',
        operator : 'eq',
        value : userCompany.company_id
      }
    ];
    //FIXME:: This logic has changed
    if(currentActivePhone.phoneId !== -100000){
      const phoneFilter = {
        field : 'phone_id',
        key: 'phoneId',
        operator : 'eq',
        value : currentActivePhone.phoneId
      }
      filters.push(phoneFilter);
    }
    if(filterOptions.user){
      const userFilter = {
        field : 'user_id',
        key: 'userId',
        operator : 'eq',
        value : filterOptions.user
      }
      filters.push(userFilter);
    }
    if(filterOptions.tag && filterOptions.tag.length > 0) {
      const tagFilter = {
        field : 'tag',
        key: 'id',
        operator : 'eq',
        value : filterOptions.tag
      }
      filters.push(tagFilter);
    }
    if(filterOptions.date){
      const dateFilter = {
        field : 'created_at',
        key: 'createdAt',
        operator : 'btw',
        start : filterOptions.date.startDate,
        end : filterOptions.date.endDate ? filterOptions.date.endDate : new Date()
      }
      filters.push(dateFilter);
    }
    if(filterOptions.recipient){
      const recipientFiler = {
        field : 'recipient',
        key: 'recipient',
        operator : 'eq',
        value : filterOptions.recipient
      }
      filters.push(recipientFiler);
    }
    return filters;
  }

  const fetchSearchResult = useCallback((pageNum) => {
    const payload = buildFilterObj();
    const queryParams = `pageNum=${pageNum}`
    SearchAPI.searchMessages({payload,token,queryParams}).then(res => {
      if(res.data.currentPage === 0){
        setSearchData(res.data);
      }
      else{
        setSearchData(prev => {
          return{
            ...prev,
            content : [...prev.content,...res.data.content],
            currentPage : res.data.currentPage,
            totalPages : res.data.totalPages,
          }
        });
      }
    }).catch(e => console.log(e));
  },[searchData,searchPattern,filterOptions,currentActivePhone,userCompany])

  const fetchNext = useCallback(() => {
    fetchSearchResult(searchData.currentPage + 1);
  },[searchData])

  useDebouncedWatch(() => {
    if(searchPattern && searchPattern?.trim().length > 0){
      fetchSearchResult(0);
    }
    else{
      setSearchData(undefined);
    }
  },[searchPattern]);

  return (
    <RightSideBarContainer heading={"Search messages"} onClose={onSearchClose}>
      <NavbarOuterWrapper ref={searchContainer}>
        <FlexContainer
          height="auto"
          justifyContent="space-between"
          paddingLeftRight="10px"
          className="full-width-searchbar"
        >
          <SearchOuterWrapper>
            <AddPhoneontainer>
              <SearchInput placeholder="Search..." value={searchPattern} onChange={(e) => setSearchPattern(e.target.value)}/>
            </AddPhoneontainer>

            <SearchIcon
              fontSize="medium"
              className="navbar-icon icon-inside-search"
            />
            <Tooltip title="Apply filters.">
              <MDBox
                className="filter-icon-inside-search navbar-icon"
                onClick={handleOpenPopup}
                display="flex"
              >
                <Badge color="primary" badgeContent={filterLength}>
                  <FilterAltIcon className="navbar-icon" />
                </Badge>
              </MDBox>
            </Tooltip>
          </SearchOuterWrapper>
        </FlexContainer>
      </NavbarOuterWrapper>
      {searchData  && searchData.content.length > 0 ? 
        <MDBox display="flex" flexDirection="column">
          {searchPattern && <MDBox display="flex" justifyContent="flex-end" onClick={handleClear}>
            <Button>{"clear"}</Button>
          </MDBox>}
          <MDBox display="flex" justifyContent="flex-end" mt={1} mr={1}>
            <Typography fontSize={"14px"}>
              {`Showing ${searchData?.content?.length} records of ${searchData.totalItems} results`}
            </Typography>
          </MDBox>
          <MDBox id="scrollableSearch" display="flex" flexDirection="column" sx={{overflowY:'auto',height:'596px'}}>
            <InfiniteScroll
              style={{overflowX:'hidden'}}
              dataLength={searchData ? searchData?.content?.length : 0}
              next={fetchNext}
              hasMore={searchData.currentPage < searchData.totalPages}
              loader={<MDBox width="100%" sx={{overflow:'hidden'}} display="flex" justifyContent="center"><CircularProgress/></MDBox>}
              scrollableTarget="scrollableSearch"
            >
              {searchData.content?.map(item => <SearchItem searchData={item} />)}
            </InfiniteScroll>
          </MDBox>
        </MDBox>
        :
        <MDBox display="flex" flexDirection="column" justifyContent="center" alignItems="center" sx={{height:'100%',minHeight:'100%'}}>
          {searchData && <Typography>{"No results found..."}</Typography>}
        </MDBox>
      }
      <Popover
        id={popoverId}
        open={openPopover}
        anchorEl={anchorEl}
        onClose={handleClosePopup}
        sx={{
          "& .MuiPopover-paper": {
            backgroundColor: "white!important",
            display: "flex",
            justifyContent: "center",
          },
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <MDBox minWidth="300px" width="100%" height="100%" sx={{ zIndex: 999 }}>
          <TeleFilterOptions
            filterOptions={filterOptions}
            setFilterOptions={setFilterOptions}
            onFilterApplied={onFilterApplied}
          />
        </MDBox>
      </Popover>
    </RightSideBarContainer>
  );
};
export default memo(TeleGlobalSearch);
