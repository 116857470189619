import { BASE_URL } from "constants/Endpoints";
import axios from "../core/axios";

//This arrange step step we need to call the APIs for initial load
const Init = {
  
  getUserDetails : async({token}) => {
    return axios.get(`${BASE_URL}/users/details`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });    
  },

  //GET DLC
  getDlc : async({token}) => {
    return axios.get(`${BASE_URL}/settings/dlc`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });    
  },

  //GET ALL RECIPIENTS AND THE ASSOCIATED CHAT
  getCompanyTags: async({ company_id, token }) => {
    return axios.get(`${BASE_URL}/company/${company_id}/tags`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
  },
  getCompanyUsers: async({ company_id, user_id, token }) => {
    const payload = { company_id: company_id, user_id: user_id };
    return axios.post(`${BASE_URL}/users`, JSON.stringify(payload), {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
  },
};
export default Init;
