import MDBox from "lib/components/MDBox";
import { useEffect, useState } from "react";
import { memo } from "react";
import MDTypography from "lib/components/MDTypography";
import { Button, MenuItem, Popover, TextField, Typography } from "@mui/material";
import CustomSelect from "ui/Select";
import { useSelector } from "react-redux";
import Init from "services/Init";
import CustomDateRangePicker from "components/util/CustomDateRangePicker";
import { DateRange } from "@mui/icons-material";
import usePopup from "hooks/use-popup";
import Moment from "react-moment";
import { useCallback } from "react";
import { toast } from "react-toastify";

const TeleFilterOptions = ({
  onFilterApplied,
  filterOptions,
  setFilterOptions,
}) => {
  const currentUser = useSelector((state) => state.user);

  const [tags, setTags] = useState([]);
  const [users, setUsers] = useState([]);
  const [date,setDate] = useState();
  const [recipient,setRecipient] = useState(filterOptions.recipient ? filterOptions.recipient : '' );
  const [recipientErr,setRecipientErr] = useState();

  const {
    popoverId,
    anchorEl,
    openPopover,
    handleOpenPopup,
    handleClosePopup,
  } = usePopup();

  const handleRecipient = useCallback((e) => {
    // if recipient is valid
    const value = e.target.value;
    const sanitizedVal = value.replace(/[`~!@#$%^*()_|+\-=?;:'",.<>\s\{\}\[\]\\\/]/gi,""); 
    if(!(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/).test(sanitizedVal)){
      setRecipient(sanitizedVal);
      setRecipientErr(true);
      return;
    }
    setFilterOptions((prev) => {
      return {
        ...prev,
        recipient: sanitizedVal,
      };
    });
    setRecipientErr(false);
    setRecipient(sanitizedVal)    
  },[setFilterOptions])

  const onRangeApply = (range) => {
    setDate(range);
    handleClosePopup();
    setFilterOptions(prev => {
      return{
        ...prev,
        date : range
      }
    })
  };

  useEffect(() => {
    if (currentUser) {
      Init.getCompanyTags({
        company_id: currentUser.company?.company_id,
        token: currentUser.token,
      })
        .then((res) => {
          setTags(res.data);
        })
        .catch((err) => console.log(err));
      Init.getCompanyUsers({
        company_id: currentUser.company?.company_id,
        token: currentUser.token,
        user_id: currentUser.id,
      })
        .then((res) => {
          setUsers(res.data);
        })
        .catch((e) => console.log(e));
    }
  }, [currentUser]);

  return (
    <MDBox
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      {/***TAG FILTER */}
      <MDBox display="flex" width="100%" flexDirection="column" p={1}>
        {/**Chip area when TAG will be selected */}
        <MDTypography
          variant="subtitle2"
          fontSize={"12px"}
          sx={{ fontWeight: 600 }}
        >
          {"Apply tags"}
        </MDTypography>
        <CustomSelect
          onChange={(e) =>
            setFilterOptions((prev) => {
              return {
                ...prev,
                tag: e.target.value,
              };
            })
          }
          value={filterOptions.tag}
          key={"tags"}
          sx={{ width: "100%", height: "32px" }}
        >
          <MenuItem value="">{"No option selected"}</MenuItem>
          {tags && tags?.map((itm) => (
            <MenuItem
              key={itm.tagId}
              sx={{
                backgroundColor: `${itm.color}!important`,
                color: "white!important",
                padding: "8px",
                margin: "8px",
              }}
              value={itm.tagId}
            >
              {itm.name}
            </MenuItem>
          ))}
        </CustomSelect>
      </MDBox>
      {/**Replied by users */}
      <MDBox display="flex" width="100%" flexDirection="column" p={1}>
        {/**Chip area when users will be selected */}
        <MDTypography
          variant="subtitle2"
          fontSize={"12px"}
          sx={{ fontWeight: 600 }}
        >
          {"Replied by users"}
        </MDTypography>
        <CustomSelect
          onChange={(e) =>
            setFilterOptions((prev) => {
              return {
                ...prev,
                user: e.target.value,
              };
            })
          }
          value={filterOptions.user}
          key={"users"}
          sx={{ width: "100%", height: "32px" }}
        >
          <MenuItem value="">{"No option selected"}</MenuItem>
          {users.map((itm) => (
            <MenuItem key={itm.userId} value={itm.userId}>
              {itm.fullName}
            </MenuItem>
          ))}
        </CustomSelect>
      </MDBox>
      <MDBox display="flex" width="100%" flexDirection="column" p={1}>
        <MDTypography
          variant="subtitle2"
          fontSize={"12px"}
          sx={{ fontWeight: 600 }}
        >
          {"Enter recipient"}
        </MDTypography>
        <TextField 
          value={recipient}
          onChange={handleRecipient} 
          sx={{height:'100%!important'}}
        />
        {recipientErr && <MDTypography fontSize={"12px"} color="error">
            {"Please enter a valid number"}
        </MDTypography>}
      </MDBox>
      <MDBox display="flex" width="100%" flexDirection="column" p={1}>
        <MDBox display="flex">
          <MDTypography
            fontSize="12px"
            variant="subtitle2"
            sx={{ fontWeight: 600 }}
          >
            Date Range
          </MDTypography>
          <DateRange
            onClick={handleOpenPopup}
            sx={{ cursor: "pointer", marginLeft: "12px" }}
          />
        </MDBox>
          {date && <MDBox display="flex" flexDirection="column">
              <MDBox display="flex">
                <Typography fontSize={"13px"}>Start date : <Moment format="MMMM Do YYYY">{date.startDate}</Moment></Typography>
              </MDBox>
              <MDBox display="flex">
                <Typography fontSize={"13px"}>End date : <Moment format="MMMM Do YYYY">{date.endDate}</Moment></Typography>
              </MDBox>
            </MDBox>}
        <Popover
          id={popoverId}
          open={openPopover}
          anchorEl={anchorEl}
          onClose={handleClosePopup}
          sx={{
            "& .MuiPopover-paper": {
              backgroundColor: "white!important",
              display: "flex",
              justifyContent: "center",
            },
          }}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        >
          <MDBox width="100%" height="100%" sx={{ zIndex: 999 }}>
            <CustomDateRangePicker
              onRangeApply={onRangeApply}
              onCancel={handleClosePopup}
            />
          </MDBox>
        </Popover>
      </MDBox>
      <MDBox
        display="flex"
        justifyContent="flex-end"
        width="100%"
        alignItems="center"
        p={1}
      >
        <Button onClick={() => {
            setDate(undefined);
            setFilterOptions({ user: "", date: "", tag: "" });
            setTags([]);
            setUsers([])
          }}>
          {"Reset"}
        </Button>
        <Button
          onClick={onFilterApplied}
          variant="contained"
          sx={{
            borderRadius: "0px",
            color: "white!important",
            border: "unset",
            marginLeft: "12px",
            height: "32px",
            minHeight: "unset",
          }}
        >
          {"Apply filter"}
        </Button>
      </MDBox>
    </MDBox>
  );
};
export default memo(TeleFilterOptions);
