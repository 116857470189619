import { Add } from "@mui/icons-material";
import { Button, Tab, Tabs } from "@mui/material";
import KeyValue from "components/CommonUI/KeyValue";
import MDBox from "lib/components/MDBox";
import { useCallback, useState, useEffect } from "react";
import { toast } from "react-toastify";
import AuthParams from "./AuthParams";
import { CARRIER_REQ_PARAMS_OPT } from "constants/AppConstants";

const TAB = {
  auth: "auth",
  headers: "headers",
  params: "params",
  body: "body",
};

let itemIdCounter = 0; // Unique ID counter

const OutboundRequestConfig = ({
  onKeyValueChange,
  carrierData,
  onmediaTypeChange,
}) => {
  const [currentTab, setCurrentTab] = useState(TAB.headers);
  const [isAuthValid, setIsAuthValid] = useState(true);
  const [selectedImageType, setSelectedImageType] = useState(
    carrierData?.media_type || ""
  );
  // Initialize keyValue state with carrierData if provided, otherwise fallback to default empty structure
  const [keyValue, setKeyValue] = useState({
    headers: carrierData?.request_body?.headers?.map((item, index) => ({
      type: TAB.headers,
      key: item.key || "",
      value: item.value || "",
      param: item.param || "",
      id: item.id || itemIdCounter++,
    })) || [{ type: TAB.headers, key: "", param: "", id: itemIdCounter++ }],
    params: carrierData?.request_body?.params?.map((item, index) => ({
      type: TAB.params,
      key: item.key || "",
      value: item.value || "",
      param: item.param || "",
      id: item.id || itemIdCounter++,
    })) || [{ type: TAB.params, key: "", param: "", id: itemIdCounter++ }],
    body: carrierData?.request_body?.body?.map((item, index) => ({
      type: TAB.body,
      key: item.key || "",
      value: item.value || "",
      param: item.param || "",
      id: item.id || itemIdCounter++,
    })) || [{ type: TAB.body, key: "", param: "", id: itemIdCounter++ }],
    auth: carrierData?.request_body?.auth || {
      name: "No Auth",
      key: "no_auth",
      param: "",
    },
  });

  useEffect(() => {
    onKeyValueChange(keyValue); // Notify parent with the updated keyValue
  }, [keyValue]);

  const handleAuthChange = (newAuthValue) => {
    setIsAuthValid(newAuthValue.valid);
    setKeyValue((prev) => ({
      ...prev,
      auth: { ...newAuthValue },
    }));
  };

  const isEmpty = (value) => {
    return value == null || (typeof value === "string" && value.trim() === "");
  };

  // The below fns are used to validate sections and Key Value pairs to avoid empty data or partially filled data to be saved.
  const areAllFieldsFilled = (section) => {
    return keyValue[section].every(
      (item) => !isEmpty(item.key) && !isEmpty(item.value)
    );
  };

  const validateAllSections = (section) => {
    if (section === "auth") return true;
    if (keyValue[section].length === 1) return true;
    return keyValue[section].every(
      (item) => !isEmpty(item.key) && !isEmpty(item.value)
    );
  };

  const handleChange = (event, newValue) => {
    if (validateAllSections(currentTab)) {
      setCurrentTab(newValue);
    } else {
      toast.warn("Please fill out all the fields before switching tabs.");
    }
  };

  // This fn prepares a list of available keys for mapping custom keys of Carrier with our used keys.
  const getAvailableOptions = (currentItem) => {
    const selectedParams = new Set();

    ["headers", "params", "body"].forEach((section) => {
      keyValue[section]?.forEach((item) => {
        if (item.param && item.param !== "" && item.id !== currentItem.id) {
          selectedParams.add(item.param);
        }
      });
    });

    return CARRIER_REQ_PARAMS_OPT.filter(
      (option) =>
        !selectedParams.has(option.key) || option.key === currentItem.param
    );
  };

  const updateKeyValue = useCallback(
    (value) => {
      setKeyValue((prev) => {
        const updatedSection = prev[value.type].map((itm) =>
          itm.id === value.id ? value : itm
        );
        return {
          ...prev,
          [value.type]: updatedSection,
        };
      });
    },
    [setKeyValue]
  );

  // Generic Fns to handle Create, Delete Operations for Key Value Pairs
  const deleteHeader = useCallback(
    (itm) => {
      if (itm.param === "media") {
        setSelectedImageType(""); // Reset image type if "media" key is deleted
        onmediaTypeChange("");
      }

      if (keyValue.headers.length === 1) {
        setKeyValue((prev) => ({
          ...prev,
          headers: [
            { type: TAB.headers, key: "", param: "", id: itemIdCounter++ },
          ],
        }));
        return;
      }

      const newHeaders = keyValue.headers.filter((item) => itm.id !== item.id);
      setKeyValue((prev) => ({
        ...prev,
        headers: newHeaders,
      }));
    },
    [keyValue.headers]
  );

  const deleteParams = useCallback(
    (itm) => {
      if (itm.param === "media") {
        setSelectedImageType("");
        onmediaTypeChange("");
      }

      if (keyValue.params.length === 1) {
        setKeyValue((prev) => ({
          ...prev,
          params: [
            { type: TAB.params, key: "", param: "", id: itemIdCounter++ },
          ],
        }));
        return;
      }

      const newParams = keyValue.params.filter((item) => itm.id !== item.id);
      setKeyValue((prev) => ({
        ...prev,
        params: newParams,
      }));
    },
    [keyValue.params]
  );

  const deleteBody = useCallback(
    (itm) => {
      if (itm.param === "media") {
        setSelectedImageType("");
        onmediaTypeChange("");
      }

      if (keyValue.body.length === 1) {
        setKeyValue((prev) => ({
          ...prev,
          body: [{ type: TAB.body, key: "", param: "", id: itemIdCounter++ }],
        }));
        return;
      }

      const newBody = keyValue.body.filter((item) => itm.id !== item.id);
      setKeyValue((prev) => ({
        ...prev,
        body: newBody,
      }));
    },
    [keyValue.body]
  );

  const addMoreHeaders = useCallback(() => {
    if (areAllFieldsFilled("headers")) {
      setKeyValue((prev) => ({
        ...prev,
        headers: [
          ...prev.headers,
          {
            type: TAB.headers,
            key: "",
            param: "",
            id: itemIdCounter++,
          },
        ],
      }));
    } else {
      toast.warn("Please fill out all headers before adding more.");
    }
  }, [setKeyValue, keyValue.headers]);

  const addMoreParams = useCallback(() => {
    if (areAllFieldsFilled("params")) {
      setKeyValue((prev) => ({
        ...prev,
        params: [
          ...prev.params,
          {
            type: TAB.params,
            key: "",
            param: "",
            id: itemIdCounter++,
          },
        ],
      }));
    } else {
      toast.warn("Please fill out all params before adding more.");
    }
  }, [setKeyValue, keyValue.params]);

  const addMoreBody = useCallback(() => {
    if (areAllFieldsFilled("body")) {
      setKeyValue((prev) => ({
        ...prev,
        body: [
          ...prev.body,
          {
            type: TAB.body,
            key: "",
            param: "",
            id: itemIdCounter++,
          },
        ],
      }));
    } else {
      toast.warn("Please fill out all body fields before adding more.");
    }
  }, [setKeyValue, keyValue.body]);
  console.log("Current Tab", currentTab, "Key", keyValue);

  return (
    <>
      <MDBox sx={{ width: "100%" }}>
        <Tabs
          value={currentTab}
          onChange={handleChange}
          textColor="secondary"
          aria-label="secondary tabs example"
          sx={{
            backgroundColor: "#fbfbfb",
            borderRadius: "4px",
          }}
        >
          <Tab value={TAB.headers} label="Headers" />
          <Tab value={TAB.auth} label="Auth" />
          <Tab value={TAB.params} label="Params" />
          <Tab value={TAB.body} label="Body" />
        </Tabs>

        {currentTab === TAB.auth && (
          <MDBox display="flex" width="100%" flexDirection="column">
            <AuthParams
              onAuthChange={handleAuthChange}
              authValue={keyValue.auth}
            />
          </MDBox>
        )}

        {currentTab === TAB.headers && (
          <MDBox display="flex" width="100%" flexDirection="column">
            <MDBox
              display="flex"
              sx={{ height: "28px" }}
              width="100%"
              justifyContent="flex-end"
            >
              <Button onClick={addMoreHeaders} startIcon={<Add />}>
                {"Add more"}
              </Button>
            </MDBox>
            {keyValue.headers.map((itm, index) => (
              <MDBox
                key={`header_${itm.id}`}
                display="flex"
                flexDirection="row"
                alignItems="center"
              >
                <KeyValue
                  item={itm}
                  onChange={updateKeyValue}
                  onDelete={() => deleteHeader(itm)}
                  availableOptions={getAvailableOptions(itm)}
                  isDeletable={keyValue.headers.length > 1} // Enable delete only if more than one item exists
                  selectedImageType={selectedImageType}
                  setSelectedImageType={setSelectedImageType}
                  onmediaTypeChange={onmediaTypeChange}
                  mediaModalClose={carrierData?.mediaModalClose}
                />
              </MDBox>
            ))}
          </MDBox>
        )}

        {currentTab === TAB.params && (
          <MDBox display="flex" width="100%" flexDirection="column">
            <MDBox
              display="flex"
              sx={{ height: "28px" }}
              width="100%"
              justifyContent="flex-end"
            >
              <Button onClick={addMoreParams} startIcon={<Add />}>
                {"Add more"}
              </Button>
            </MDBox>
            {keyValue.params.map((itm, index) => (
              <MDBox
                key={`param_${itm.id}`}
                display="flex"
                flexDirection="row"
                alignItems="center"
              >
                <KeyValue
                  item={itm}
                  onChange={updateKeyValue}
                  onDelete={() => deleteParams(itm)}
                  availableOptions={getAvailableOptions(itm)}
                  isDeletable={keyValue.params.length > 1} // Enable delete only if more than one item exists
                  selectedImageType={selectedImageType}
                  setSelectedImageType={setSelectedImageType}
                  onmediaTypeChange={onmediaTypeChange}
                  mediaModalClose={carrierData?.mediaModalClose}
                />
              </MDBox>
            ))}
          </MDBox>
        )}

        {currentTab === TAB.body && (
          <MDBox display="flex" width="100%" flexDirection="column">
            <MDBox
              display="flex"
              sx={{ height: "28px" }}
              width="100%"
              justifyContent="flex-end"
            >
              <Button onClick={addMoreBody} startIcon={<Add />}>
                {"Add more"}
              </Button>
            </MDBox>
            {keyValue.body.map((itm, index) => (
              <MDBox
                key={`body_${itm.id}`}
                display="flex"
                flexDirection="row"
                alignItems="center"
              >
                <KeyValue
                  item={itm}
                  onChange={updateKeyValue}
                  onDelete={() => deleteBody(itm)}
                  availableOptions={getAvailableOptions(itm)}
                  isDeletable={keyValue.body.length > 1} // Enable delete only if more than one item exists
                  selectedImageType={selectedImageType}
                  setSelectedImageType={setSelectedImageType}
                  onmediaTypeChange={onmediaTypeChange}
                  mediaModalClose={carrierData?.mediaModalClose}
                />
              </MDBox>
            ))}
          </MDBox>
        )}
      </MDBox>
    </>
  );
};

export default OutboundRequestConfig;
