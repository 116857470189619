import { Add, Close } from "@mui/icons-material";
import { Button } from "@mui/material";
import MDBox from "lib/components/MDBox";
import { useState } from "react";
import AppModal from "ui/AppModal";
import UserForm from "./UserForm";
import UserItem from "./UserItem";

const AddUser = ({users,setUsers}) => {

    const [addUser,setAddUser] = useState(false);

    const removeHandler = (idx) => {
        const filtered = users.filter((item,i) => i !== idx);
        setTimeout(() => {
            setUsers(filtered);
        },500);
    }
    const renderusers = () => {
        return users?.map((item,index) =>
            <MDBox key={"md_"+index} display="flex" alignItems="center" mb={2}>
                <UserItem user={item}/>
                <Close sx={{cursor:"pointer"}} onClick={removeHandler.bind(null,index)}/>
            </MDBox>         
        )
    }
    return(
        <>
            <MDBox>
                <Button onClick={() => setAddUser(true)}><Add/> Add user</Button>
                {users?.length > 0 && renderusers()}
            </MDBox>
            {addUser && <AppModal heading={" "} onModalClose={() => setAddUser(false)}>
                <UserForm onCreated={(res) => {
                    setUsers(prev => [...prev,res]);
                    setAddUser(false);
                }}/>
            </AppModal>}
        </>
    )
}
export default AddUser;