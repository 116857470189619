import { Icon, Menu, Paper, MenuItem } from "@mui/material";
import MDBox from "lib/components/MDBox";
import MDButton from "lib/components/MDButton";
import React, { useState } from "react";

const ArrowAddBtn = ({
  text,
  onClick,
  customStyle,
  menuItems,
  onPopoverClose,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    onPopoverClose();
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <MDBox
        pt={2}
        px={2}
        sx={customStyle}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <MDButton onClick={handleClick} variant="gradient" color="dark">
          {text}&nbsp;
          <Icon sx={{ fontWeight: "bold" }}>keyboard_arrow_down_icon</Icon>
        </MDButton>
      </MDBox>
      <Paper>
        <Menu
          onClose={handleClose}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          sx={{
            "& .MuiPopover-paper": {
              backgroundColor: "white!important",
              display: "flex",
              justifyContent: "center",

              zIndex: 1000,
            },
          }}
        >
          {React.cloneElement(menuItems, { handleMenuClose })}
        </Menu>
      </Paper>
    </>
  );
};
export default ArrowAddBtn;
