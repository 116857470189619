/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 React components
import MDBox from "lib/components/MDBox";
import MDTypography from "lib/components/MDTypography";
import MDInput from "lib/components/MDInput";
import MDButton from "lib/components/MDButton";

// Authentication layout components
import BasicLayout from "lib/layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-up-cover.webp";
import { useEffect, useRef, useState } from "react";
import useHttp from "hooks/use-http";
import {createUser as registerFn} from "api/user";
import { toast } from "react-toastify";
import {useNavigate} from "react-router-dom";
import { IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

function Register() {

  const nameRef = useRef();
  const emailRef = useRef();
  const passwordRef = useRef();
  const confirmPasswordRef = useRef();
  const navigate = useNavigate();
  // const dispatch = useDispatch();

  const [passVisible, setPassVisible] = useState(false);


  const [httpErr , setHttpErr] = useState('');

  const {sendRequest, data , status, error} = useHttp(registerFn,false);

  const onFormSubmitHandler = (event) => {
    event.preventDefault();
    /// Collect data
    const user = {
      fullName : nameRef.current.value,
      email : emailRef.current.value,
      password : passwordRef.current.value,
      roles :[{
        "name" : "ADMIN"
      }]
    }     
    if(passwordRef.current.value !== confirmPasswordRef.current.value){
      setHttpErr("Password does not match with confirm password.");
    }
    else if(user.email === '' || user.fullName === '' || user.password === ''){
      setHttpErr("Please fill all the fields.")
    }
    else{
      sendRequest(user);
    }
  }
  useEffect(() => {
    if(status === 'completed'){
      if(error === null){
        setHttpErr('');
        // console.log(userData)
        // const user = {
        //   id: data.userId,
        //   name : data.fullName,
        //   token : data.token,
        //   isLoggedIn : data.valid,
        //   role : data.roles[0],
        //   email : data.userEmail
        // }
        // dispatch(userAction.login(user));
        nameRef.current.value = '';
        emailRef.current.value = '';
        passwordRef.current.value = '';
        toast.success("Registed successfully, Please login now..");
        navigate("/login");

      }
      else{
        setHttpErr(error);
      }
    }
  },[status,error,data,navigate])  
  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Register here
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Enter below fields to register
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3} onSubmit = {onFormSubmitHandler}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput type="text" label="Name" variant="standard" fullWidth inputRef={nameRef}/>
            </MDBox>
            <MDBox mb={2}>
              <MDInput type="email" label="Email" variant="standard" fullWidth inputRef={emailRef} />
            </MDBox>
            <MDBox mb={2}>
              <MDInput 
                type={passVisible ? 'text' : "password"} 
                label="Password" 
                variant="standard" 
                fullWidth 
                inputRef={passwordRef}
                InputProps={{
                  endAdornment: <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setPassVisible(prev => !prev)}
                      edge="end"
                    >
                      {passVisible ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                    {/* <VisibilityIcon 
                      onClick={() => setPassVisible(prev => !prev)}
                      sx={{
                        position: "absolute",
                        right: "10%",
                        top: "40%",
                        cursor:'pointer'
                    }}/> */}
                    </InputAdornment>
                }} 
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput type={passVisible ? 'text' : "password"} label="Confirm Password" variant="standard" fullWidth  inputRef={confirmPasswordRef}/>
            </MDBox>            
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Checkbox />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;I agree the&nbsp;
              </MDTypography>
              <MDTypography
                component="a"
                href="#"
                variant="button"
                fontWeight="bold"
                color="info"
                textGradient
              >
                Terms and Conditions
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton type="submit" variant="gradient" color="info" fullWidth>
                sign up
              </MDButton>
            </MDBox>
            <MDBox display="flex" alignItems="center" justifyContent="center">
                  <MDTypography variant="subtitle2" fontWeight="regular" color="error" mt={1}>
                    <span>{httpErr}</span>
                  </MDTypography>                 
              </MDBox>              
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Already have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/login"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign In
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Register;
