import React, { useState } from "react";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  InputAdornment,
  IconButton,
  Tooltip,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
} from "@mui/material";
import MDBox from "lib/components/MDBox";
import MDButton from "lib/components/MDButton";
import { APP_COLOR } from "constants/Color";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import EditIcon from "@mui/icons-material/Edit";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import AppDialog from "ui/AppDialog";

const RequiredParamsDrop = ({
  value,
  onChange,
  options = [],
  label = "Select Option",
  style = {},
  onImageTypeChange,
  imageType, // Current imageType passed as a prop
  mediaModalClose,
}) => {
  options = [...options, { key: "others", value: "Other" }];


  console.log("Image Type", imageType)
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedImageType, setSelectedImageType] = useState(imageType);

  const handleDropdownChange = (event) => {
    const selectedValue = event.target.value;
    onChange(selectedValue === "others" ? "" : selectedValue);

    if (selectedValue === "media") {
      setIsDialogOpen(true);
    }
  };

  const handleImageTypeChange = (event) => {
    setSelectedImageType(event.target.value);
  };

  const handleDialogSave = () => {
    onImageTypeChange(selectedImageType);
    setIsDialogOpen(false);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  return (
    <MDBox
      width="100%"
      style={{ ...style, display: "flex", alignItems: "center" }}
    >
      <FormControl fullWidth variant="outlined" size="small">
        <InputLabel>{label}</InputLabel>
        <Select
          value={value === "" ? "others" : value}
          onChange={handleDropdownChange}
          label={label}
          sx={{
            height: "44px",
            "& .MuiSelect-select": {
              fontSize: "0.875rem",
              padding: "10px 14px",
              color: APP_COLOR.APP_SECONDARY_COLOR,
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: APP_COLOR.APP_SECONDARY_COLOR,
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: APP_COLOR.APP_SECONDARY_COLOR,
            },
          }}
          endAdornment={
            <InputAdornment position="end">
              {value === "media" && (
                <Tooltip title="Edit Image Value Type">
                  <IconButton
                    size="small"
                    onClick={() => setIsDialogOpen(true)}
                    sx={{
                      padding: "0",
                      color: APP_COLOR.APP_SECONDARY_COLOR,
                    }}
                  >
                    <EditIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
              <IconButton
                size="large"
                sx={{
                  padding: "0",
                  margin: "0",
                  color: APP_COLOR.APP_SECONDARY_COLOR,
                }}
              >
                <ArrowDropDownIcon
                  fontSize="inherit"
                  sx={{ fontSize: "1.5rem" }}
                />
              </IconButton>
            </InputAdornment>
          }
        >
          {options.map((option) => (
            <MenuItem
              key={option.key}
              value={option.key}
              sx={{ fontSize: "0.875rem" }}
            >
              {option.value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* AppDialog for Image Type Selection */}
      <AppDialog
        show={isDialogOpen}
        onClose={handleDialogClose}
        mediaModalClose={mediaModalClose}
        title="Select Image Value Type"
        content={
          <>
            <MDBox
              display="flex"
              alignItems="center"
              sx={{
                backgroundColor: "#fbfbfb",
                padding: "8px 12px",
                borderRadius: "8px",
                mb: 2,
              }}
            >
              <InfoOutlinedIcon sx={{ mr: 1 }} />{" "}
              <Typography variant="body2" color="black">
                You can add multiple images by separating them with commas in
                the value field.
              </Typography>
            </MDBox>
            <RadioGroup
              value={selectedImageType}
              onChange={handleImageTypeChange}
              sx={{ ml: "30px" }}
            >
              <FormControlLabel
                value="single"
                control={<Radio />}
                label="Single"
              />
              <FormControlLabel value="list" control={<Radio />} label="List" />
            </RadioGroup>
          </>
        }
        footer={
          <MDButton onClick={handleDialogSave} variant="gradient" color="info">
            Save
          </MDButton>
        }
      />
    </MDBox>
  );
};

export default RequiredParamsDrop;
