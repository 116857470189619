import MDBox from "lib/components/MDBox";
import { Button, Card } from "@mui/material";
import { useCallback, useState } from "react";
import ProfileInfo from "./ProfileInfo";
import DlcInfo from "./DlcInfo";
import CreateUsers from "./CreateUsers";
import FinishProfile from "./FinishProfile";
import { validateProfileInfo } from "util/Iframeform";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { IframeAPI } from "services/Iframe";

const CompanyProfile = () => {
  
  const [steps,setSteps] = useState(0);
  const{planName} = useParams();

  const [profileData, setProfileData] = useState({
    firstName: "",
    lastName: "",
    companyName: "",
    email: "",
    contactNumber: "",
    creditCardNumber: "",
    expiration: "",
    cvv: "",
    address: "",
    address1: "",
    city: "",
    state: "",
    zipcode: "",
    plan : "",
  });

  const [profileValidation,setProfileValidation] = useState({
    firstName: false,
    lastName: false,
    companyName: false,
    email: false,
    contactNumber: false,
    creditCardNumber: false,
    expiration: false,
    cvv: false,
    address: false,
    city: false,
    state: false,
    zipcode: false,
  })

  const [dlcData,setDlcData] = useState({
    existingNumber : "no",
    assignNewNumber : "yes",
    dlcName : "",
    dlcNumber : "",
    hipaaCompliance : "no",
    numbers : []
  });

  const [users,setUsers] = useState([]);

  const nextHandler = useCallback(() => {
    if(steps === 0){
      const {newValidation , validationFailed} = validateProfileInfo(profileData,profileValidation);
      if(validationFailed){
        setProfileValidation(newValidation);
        return;
      }
    }
    setSteps(prev => prev + 1);
  },[profileData])

  const prevhandler = () => {
    setSteps(prev => prev - 1);
  }

  const onSubmitHandler = (e) => {
    e.preventDefault();
    const payload = {
      dlcData,profileData,users
    }
    IframeAPI.saveCustomerDetails(payload).then(res => {
      setSteps(prev => prev + 1);
    }).catch(e => {

    });
  }

  useEffect(() => {
    if(planName){
      setProfileData(prev => {
        return{
          ...prev,
          plan : planName
        }
      })
    }
  },[planName]);

  return (
    <Card sx={{ marginTop: "16px", width: "100%",padding:'12px' }}>
      <MDBox sx={{
        height: "calc(100vh - 180px)",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
            width: "0.3em",
            height: "0.4em"
          },
          "&::-webkit-scrollbar-thumb" : {
            borderRadius:" 4px",
            backgroundColor: "#8b8b8b"
          }            
        }}
        pt={2}
        pb={3}
        px={3}
      >
        {steps === 0 && <ProfileInfo profileValidation={profileValidation} setProfileValidation={setProfileValidation} profileData={profileData} setProfileData={setProfileData}/>}
        {steps === 1 && <DlcInfo dlcData={dlcData} setDlcData={setDlcData}/>}
        {steps === 2 && <CreateUsers setUsers={setUsers} users={users}/>}
        {steps === 3 && <FinishProfile/>}
      </MDBox>
      <MDBox display="flex" justifyContent="flex-end" mt={2} mb={2} mr={2}>
        {steps > 0 && steps < 3 && <Button 
            sx={{
                borderRadius: "3px",
                color: "black!important",
                border: "unset",
            }}
            onClick={prevhandler}
          >
            {'Back'}
          </Button>
        }
        {steps !== 3 && <Button 
            variant="contained"
            sx={{
                borderRadius: "3px",
                color: "white!important",
                border: "unset",
            }}
            onClick={steps == 2 ?  onSubmitHandler : nextHandler }
        >
            {steps == 2 ? "Submit" : 'Next'}
        </Button>}
      </MDBox>
    </Card>
  );
};
export default CompanyProfile;
