import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import MDBox from "lib/components/MDBox";
import MDInput from "lib/components/MDInput";
import { memo, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import OutboundRequestConfig from "./OutboundRequestConfig";
import { Refresh } from "@mui/icons-material";
import { getCarrierResponse } from "services/CarrierAPI";
import { loaderAction } from "store/loader-slice";
import { toast } from "react-toastify";
import { CARRIER_REQ_PARAMS_OPT } from "constants/AppConstants";

const OutboundConfig = ({ carrierData, setCarrierData }) => {
  const token = useSelector((state) => state.user.token);
  const dispatch = useDispatch();

  const [responseKeys, setResponseKeys] = useState(
    Object.keys(carrierData?.response_body) || []
  );

  const handleKeyValueChange = useCallback(
    (newKeyValue) => {
      setCarrierData((prev) => ({
        ...prev,
        request_body: newKeyValue,
      }));
    },
    [setCarrierData]
  );

  const isEmpty = (value) =>
    value == null || (typeof value === "string" && value.trim() === "");

  const onFetchResponse = async () => {
    if (isEmpty(carrierData?.send_url)) {
      toast.error("API Send URL is missing!");
      return;
    }

    const validateParams = () => {
      const selectedParams = new Set();
      const keysSet = new Set();
      const duplicates = new Set();
      const keyValue = carrierData?.request_body;

      ["headers", "params", "body"].forEach((section) => {
        keyValue[section].forEach((item) => {
          if (!isEmpty(item?.param) && !isEmpty(item?.value))
            selectedParams.add(item.param);
          if (item.key) {
            if (keysSet.has(item.key)) duplicates.add(item.key);
            else keysSet.add(item.key);
          }
        });
      });

      const missingParams = CARRIER_REQ_PARAMS_OPT.filter(
        (option) => !selectedParams.has(option.key) && option.key !== "media"
      );

      return { missingParams, duplicates: [...duplicates] };
    };

    const { missingParams, duplicates } = validateParams();

    if (missingParams.length > 0) {
      toast.error(
        `Please define values for the following params: ${missingParams
          .map((param) => param.value)
          .join(", ")}`
      );
      return;
    }
    if (duplicates.length > 0) {
      toast.error(
        `Duplicate keys found: ${duplicates.join(", ")}. Keys must be unique.`
      );
      return;
    }

    try {
      dispatch(loaderAction.show());
      const response = await getCarrierResponse(carrierData, token);

      setCarrierData((prev) => ({
        ...prev,
        response_body:
          JSON.stringify(response?.data, null, 2) || response?.message,
      }));

      if (response.status === 200) {
        toast.success("Carrier Response Is Valid");
        console.log("Response", response?.data);
        const responseData = response?.data || {};
        setResponseKeys(Object.keys(responseData));
      } else {
        toast.error("Carrier Response Not Valid");
      }
      dispatch(loaderAction.hide());
    } catch (error) {
      dispatch(loaderAction.hide());
      toast.error("Error fetching the response. Please try again.");
    }
  };

  const onHttpMethodChange = (e) => {
    setCarrierData((prev) => ({
      ...prev,
      http_method: e.target.value,
    }));
  };

  const onSendUrlChange = (e) => {
    setCarrierData((prev) => ({
      ...prev,
      send_url: e.target.value,
    }));
  };

  const onResponseTypeChange = (e) => {
    const value = e.target.value;
    setCarrierData((prev) => ({
      ...prev,
      callback_type: value,
    }));
    if (value === "immediate") {
      // When user selects Immediate , we need to remove later value also if pre selected
      setCarrierData((prev) => ({
        ...prev,
        later: "",
      }));
    }
  };

  const onLaterKeyChange = (e) => {
    setCarrierData((prev) => ({
      ...prev,
      later: e.target.value,
    }));
  };

  const onmediaTypeChange = (mediaType) => {
    setCarrierData((prev) => ({
      ...prev,
      media_type: mediaType,
    }));
  };

  return (
    <MDBox>
      <MDBox display="flex" justifyContent="center">
        <Typography variant="h5" m={2}>
          {"Outbound configurations"}
        </Typography>
      </MDBox>

      <MDBox
        margin={"8px 8px 12px 8px"}
        display="flex"
        flexDirection="row"
        justifyContent="flex-start"
      >
        <MDBox>
          <FormControl fullWidth>
            <InputLabel id="http-method">Http Methods</InputLabel>
            <Select
              labelId="http-method"
              id="http-method-select"
              value={carrierData.http_method}
              sx={{ height: "42px", marginRight: "8px" }}
              label="HTTP Methods"
              onChange={onHttpMethodChange}
            >
              <MenuItem value={"POST"}>HTTP POST</MenuItem>
              <MenuItem value={"PUT"}>HTTP PUT</MenuItem>
              <MenuItem value={"GET"}>HTTP GET</MenuItem>
            </Select>
          </FormControl>
        </MDBox>
        <MDBox width="100%!important">
          <MDInput
            fullWidth
            onChange={onSendUrlChange}
            type="text"
            label="Send URL"
            value={carrierData.send_url}
          />
        </MDBox>
      </MDBox>

      <MDBox margin={"12px 0px 12px 0px"} width="100%">
        <OutboundRequestConfig
          onKeyValueChange={handleKeyValueChange}
          carrierData={carrierData}
          onmediaTypeChange={onmediaTypeChange}
        />
      </MDBox>

      <MDBox sx={{ position: "relative", marginTop: "12px" }}>
        <MDInput
          multiline
          minRows={4}
          sx={{ paddingRight: "48px" }}
          fullWidth
          label="MT Response check"
          value={
            typeof carrierData?.response_body === "string"
              ? carrierData?.response_body
              : JSON.stringify(carrierData?.response_body, null, 2)
          }
        />
        <Button
          sx={{
            position: "absolute",
            bottom: "26px",
            right: "34px",
          }}
          startIcon={<Refresh />}
          onClick={onFetchResponse}
        >
          {"Fetch response"}
        </Button>
        <Typography margin={"8px"} fontSize={"12px"}>
          The content that must be in the response to consider the request
          successful
        </Typography>
      </MDBox>

      {carrierData.response_body && responseKeys.length > 0 && (
        <MDBox margin={"12px 0px"} display="flex" alignItems="center" gap={2}>
          <FormControl component="fieldset" sx={{ minWidth: 150 }}>
            <FormLabel
              sx={{
                fontSize: "0.875rem",
                color: "text.secondary",
                "&.Mui-focused": { color: "text.secondary" }, // Prevent color change when active
                "&:hover": { color: "text.secondary" },
              }}
            >
              Response Type
            </FormLabel>{" "}
            {/* Smaller font size */}
            <RadioGroup
              row
              value={carrierData.callback_type}
              onChange={onResponseTypeChange}
            >
              <FormControlLabel
                value="immediate"
                control={<Radio />}
                label="Immediate"
                sx={{ marginRight: 2 }}
              />
              <FormControlLabel
                value="later"
                control={<Radio />}
                label="Later"
              />
            </RadioGroup>
          </FormControl>

          {carrierData.callback_type === "later" && responseKeys.length > 0 && (
            <FormControl sx={{ minWidth: 200, mt: "15px" }} size="small">
              {" "}
              {/* Better alignment */}
              <InputLabel id="response-key-select">
                Select Response Key
              </InputLabel>
              <Select
                labelId="response-key-select"
                id="response-key-select"
                value={carrierData.later || responseKeys[0]}
                label="Select Response Key"
                onChange={onLaterKeyChange}
                endAdornment={<ArrowDropDownIcon fontSize="medium" />}
                sx={{
                  fontSize: "1rem", // Set font size for selected option
                  height: "35px",
                  marginRight: "8px",
                  cursor: "pointer",
                  ".MuiSelect-select": { fontSize: "1rem" }, // Ensure font size applies to selected option
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      fontSize: "1rem", // Set font size for dropdown options
                    },
                  },
                }}
              >
                {responseKeys.map((key) => (
                  <MenuItem key={key} value={key} sx={{ fontSize: "0.875rem" }}>
                    {key}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </MDBox>
      )}
    </MDBox>
  );
};

export default memo(OutboundConfig);
