import axios from "axios";
import authPopup from "services/AuthPopup/LoginInfoPopup";

axios.interceptors.request.use((config) => {
  if (localStorage.getItem("token")) {
    config.headers["Authorization"] = "Bearer " + localStorage.getItem("token");
    return config;
  } else {
    return config;
  }
});

axios.interceptors.response.use(
  (res) => {
    return res;
  },
 (error) => {
   if (error?.response?.status === 401) {
      const handlePopup = authPopup();
      handlePopup(error);
    }
    else{
      return Promise.reject(error);
    }
  }
);

export default axios;
