import EditIcon from "@mui/icons-material/Edit";
import { Icon,Tooltip } from "@mui/material";
import MDBox from "lib/components/MDBox";
import { memo, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AppModal from "ui/AppModal";
import EditCompanyForm from "./EditCompanyForm";
import AppDrawer from "ui/AppDrawer";
import { Delete, Settings} from "@mui/icons-material";
import CompanyDetails from "./CompanyDetails";
import { ACTIONS } from "constants/AppConstants";
import Swal from "sweetalert2";
import { CompanyAPI } from "services/CompanyAPI";
import { toast } from "react-toastify";
import { loaderAction } from "store/loader-slice";
import AppConstants from "constants/AppConstants";
import { OperationStatus } from "constants/AppConstants";

const CompanyActions = ({
  company,
  onProductUnsubscribed,
  onProductSubscribed,
  onCompanyEdited,
  onCompanyDeleted,
  onHipaaSubscribed}) => {

  const dispatch = useDispatch();

  const [action, setAction] = useState(ACTIONS.NONE);
  const [openDrawer, setOpenDrawer] = useState(false);
  const token = useSelector(state => state.user.token);
  const role = useSelector(state => state.user.role);

  const onCompanyEditedHandler = (res) => {
    if(res){
        setAction(ACTIONS.NONE);
        onCompanyEdited(res);
    }
  }
  const showCompanyDetails = (tcompany) => {
    setOpenDrawer(true);
  }

  const companyDeleteHandler = useCallback(() => {
    Swal.fire({
      title: 'Are you sure want to delete?',
      text: 'All data related to this company will be deleted including chats.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      customClass: {
        container : {
          zIndex : '10000 !important'
        }
      }
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(loaderAction.show());
        CompanyAPI.deleteCompany({token,companyId : company?.company_id}).then(res => {
          dispatch(loaderAction.hide());
          if(res.data.status === OperationStatus.success){
            toast.success("Company deleted successfully.");
            onCompanyDeleted(res.data);
          }
          else{
            toast.success(AppConstants.SOMETHING_WENT_WRONG);
          }
        }).catch(e => {
          dispatch(loaderAction.hide());
          toast.error(e?.response?.data?.message);
        })
      }
    })
  },[token,company]);
  
    return (
      <>
        <MDBox display="flex" flexDirection="row">
          {
            <Tooltip placement="bottom" title="Settings">
              <MDBox sx={{ marginLeft: "8px" }} onClick={showCompanyDetails.bind(null,company)}>
                <Icon
                  sx={{
                    height: "20px",
                    width: "40px",
                    fontSize: "20px!important",
                    cursor: "pointer",
                  }}
                >
                  <Settings />
                </Icon>
              </MDBox>
            </Tooltip>
          }
          {role === "SUPER_ADMIN" && <>
            <Tooltip title="Edit.">
              <EditIcon
                onClick={() => setAction(ACTIONS.EDIT)}
                style={{
                  marginRight: "8px",
                  cursor: "pointer",
                  width: "1.5rem",
                  height: "1.5rem",
                }}
              />
            </Tooltip>
            <Tooltip title="Delete">
              <Delete
                onClick={companyDeleteHandler}
                style={{
                  marginRight: "8px",
                  cursor: "pointer",
                  width: "1.5rem",
                  height: "1.5rem",
                }}
              />
            </Tooltip>
          </>}
        </MDBox>
        {action === ACTIONS.EDIT && role === "SUPER_ADMIN" && (
          <AppModal
            rootStyle = {
              {
                "&.MuiModal-root":{
                  width:'40%',
                  left: '60%'
                }
              }
            }
            customStyle={{ 
              width: "100%",
            }}
            onModalClose={() => {
              setAction(ACTIONS.NONE);
            }}
            heading={"Edit Company"}
          >
            <EditCompanyForm
              company = {company}
              onCompanyEdited = {onCompanyEditedHandler}
              onHipaaSubscribed = {onHipaaSubscribed}
              onProductSubscribed = {onProductSubscribed}
              onProductUnsubscribed = {onProductUnsubscribed}
            />
          </AppModal>
        )}
        <AppDrawer openDrawer={openDrawer} style={{
                margin:'unset !important',
                height:'100% !important',
                borderRadius:'unset!important',
                width:'35%'
            }}
            onClose = {() => setOpenDrawer(null)}>
              {company && <CompanyDetails company={company} onEdited={(res) =>{onCompanyEdited(res)}}/>}
        </AppDrawer>   
      </>
    );
}
export default memo(CompanyActions);