import { notify } from "util/AppNotification";

export const  isChatAlreadyAdded = (chats,userId) => {
    return chats.filter(item => item.recipientId === userId); 
}

export const formatChat = (r,userId) => {
    const ouser =  r.participants.find(itm => itm.userId !== userId);
    const me = r.participants.find(itm => itm.userId === userId);
    const tmp = {
        chatId : r.chatId,
        name : ouser.user.fullName,
        profilePic : ouser.user.profilePic,
        recipientId : ouser.userId,
        online : ouser.online,
        iEvent : undefined,
        groupChat : r?.groupChat,
        muted : me?.muted,
        meId : me.userId,
        meParticipantId : me.id,
        unreadCount : me.unreadCount ? me.unreadCount : 0, 
        participants : [{
            participantId : ouser.id,
            profilePic : ouser.user.profilePic,
            fullName : ouser.user.fullName,
            userId : ouser.userId,
            email : ouser.user.email,
            contactNumber : ouser.user.contactNumber,
            company : ouser.user.company,
            chatId : r.chatId,
        }],
        latestMessage : r?.latestMessage
    }
    return tmp;
}
export const formatGroupChat = (group,userId) => {
    
    const messageSender = group.participants.find(itm => itm.userId === group.senderId);

    const otherParticipants =  group.participants.filter(itm => itm.userId !== userId).map(item => {
        return {
            participantId : item.id,
            profilePic : item.user.profilePic,
            fullName : item.user.fullName,
            userId : item.userId,
            email : item.user.email,
            contactNumber : item.user.contactNumber,
            company : item?.user?.company,
            admin : item.userId === group?.adminId
        }
    });
    const me = group.participants.find(itm => itm.userId === userId);
    const tmp = {
        chatId : group.chatId,
        name : group?.chatName,
        description : group?.description,
        groupChat : group?.groupChat,
        meParticipantId : me?.id,
        adminId : group?.adminId,
        muted : me?.muted,
        meId : me?.userId,
        usersTyping : [],
        iEvent : undefined,
        participants : otherParticipants,
        unreadCount : me.unreadCount ? me.unreadCount : 0,
        latestMessage : group?.latestMessage,
        receivedFrom : messageSender?.fullName,
        displayProfile : group?.displayProfile,
        meParticipant : {
            participantId : me.id,
            profilePic : me.user.profilePic,
            fullName : me.user.fullName,
            userId : me.userId,
            email : me.user.email,
            contactNumber : me.user.contactNumber,
            company : me?.user?.company,
            admin : me?.userId === group?.adminId
        }
    }
    return tmp;
}
//TODO:: modify logic, currently it is not working...
export const  bringToTop = (array, object) => {
    // Find the index of the object in the array.
    let index = array.findIndex((item) => item === object);
  
    // If the object is found, remove it from the array and then insert it at the beginning of the array.
    if (index !== -1) {
      array.splice(index, 1);
      array.unshift(object);
    }
  
    // Return the modified array.
    return array;
}

export const updateChat = (chats,chat) => {
    const newChats = chats.map(c => {
        if(c.newChat && c?.tempChatId === chat?.tempChatId) {
            return {
                ...c,
                newChat : false,
                chatId : chat.chatId
            }
        }
        else return c;
    })
    return newChats;
}

export const internalChatNotification = (payload) => {
    try{
        const notificationPayload = {
            from : payload.chat.participants[0].fullName,
            body : payload.message.text,
            muted : payload?.chat?.muted ? payload?.chat?.muted : false,
            sound : undefined
        }
        notify(notificationPayload);
    }
    catch(e){}
}

export const filterUser = (users,pattern) => {
    const p = pattern?.toLowerCase();
    return users.filter(itm => {
        if(itm?.fullName?.toLowerCase().indexOf(p) > -1 || itm?.email?.toLowerCase().indexOf(p) > -1) {
            return itm;
        }
        else return undefined;
    });
}