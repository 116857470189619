import * as React from "react";
import { useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Close } from "@mui/icons-material";
import { Fade } from "@mui/material";
import { useMaterialUIController, setMiniSidenav } from "lib/context";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function AppModal({
  heading,
  children,
  showCloseButton = true,
  onModalClose,
  customStyle,
  rootStyle,
  closeOnClick = true,
}) {
  const [open, setOpen] = React.useState(true);
  const [controller, dispatch] = useMaterialUIController();
  const handleClose = () => {
    setOpen(false);
    onModalClose();
    setMiniSidenav(dispatch, false);
  };
  useEffect(() => {
    // Minimize the sidenav when the modal is opened
    if (open) {
      setMiniSidenav(dispatch, true);
    }
  }, [open, dispatch]);

  return (
    <div>
      <Modal
        open={open}
        closeAfterTransition={true}
        disableAutoFocus={true}
        onClose={closeOnClick ? handleClose : () => {}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ ...rootStyle, overflowX: "auto!important" }}
      >
        <Fade in={open}>
          <Box
            sx={style}
            style={{ ...customStyle, border: "unset", borderRadius: "12px" }}
          >
            {(heading || showCloseButton) && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  height: "1.75rem",
                }}
              >
                {heading && (
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    fontSize={"1.2rem"}
                  >
                    {heading}
                  </Typography>
                )}
                {showCloseButton && (
                  <Close
                    sx={{
                      cursor: "pointer",
                      height: "1.75rem",
                      width: "1.75rem",
                    }}
                    onClick={handleClose}
                  />
                )}
              </Box>
            )}
            <Box
              id="modal-modal-description"
              sx={{ mt: showCloseButton ? 1 : 0 }}
            >
              {children}
            </Box>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
