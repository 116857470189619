import EditIcon from "@mui/icons-material/Edit";
import { Tooltip } from "@mui/material";
import MDBox from "lib/components/MDBox";
import { useState, useEffect } from "react";
import AppModal from "ui/AppModal";
import EditCarrierForm from "./EditCarrierForm";
import { useMaterialUIController, setMiniSidenav } from "lib/context"; // Import the controller and setMiniSidenav

const CarrierAction = ({ carrier, onEdited }) => {
  const [showEditModel, setShowEditModel] = useState(false);
  const [controller, dispatch] = useMaterialUIController(); // Access the controller and dispatch
  const { miniSidenav } = controller; // Destructure the miniSidenav state

  // Minimize the sidenav when the modal is opened, and revert it when closed
  useEffect(() => {
    if (showEditModel) {
      setMiniSidenav(dispatch, true); // Minimize sidenav when modal opens
    } else {
      setMiniSidenav(dispatch, false); // Revert sidenav when modal closes
    }
  }, [showEditModel, dispatch]);

  return (
    <>
      <MDBox display="flex" flexDirection="row">
        <Tooltip title="Edit the carrier.">
          <EditIcon
            onClick={() => setShowEditModel(true)}
            style={{
              marginRight: "8px",
              cursor: "pointer",
              width: "1.5rem",
              height: "1.5rem",
            }}
          />
        </Tooltip>
      </MDBox>

      {showEditModel && (
        <AppModal
          rootStyle={{
            "&.MuiModal-root": {
              width: "90%",
              left: "10%",
            },
          }}
          customStyle={{
            width: "100%",
            height: "100%",
            overflowY: "auto",
            border: "unset",
          }}
          showCloseButton={false}
          onModalClose={() => setShowEditModel(false)}
        >
          {carrier && (
            <EditCarrierForm
              carrierId={carrier.id}
              onEdited={(res) => {
                setShowEditModel(false); // Close modal after edit
                onEdited(res);
              }}
              onBack={() => {
                setShowEditModel(false); // Close modal when "Back" button is clicked
              }}
            />
          )}
        </AppModal>
      )}
    </>
  );
};

export default CarrierAction;
