import { Button, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import UserItem from "components/IFrame/UserItem";
import MDBox from "lib/components/MDBox";
import MDInput from "lib/components/MDInput";

const DetailsForm = ({ customer }) => {
  return (
    <MDBox display="flex" width="100%">
      <MDBox width="40%">
        <Typography>{"General info"} <Typography fontFamily={"cursive"} display={"inline-flex"} sx={{color:"blue"}}>(Plan - {customer.profileData.plan})</Typography></Typography>
        <MDBox mb={2} display="flex">
          <MDBox mr={1} width="50%">
            <MDInput value={customer.profileData.firstName} type="text" label="First Name" variant="standard" fullWidth/>
          </MDBox>
          <MDBox ml={1} width="50%">
            <MDInput value={customer.profileData?.lastName} type="text" label="Last Name" variant="standard" fullWidth/>
          </MDBox>
        </MDBox>

        <MDBox mb={2}>
          <MDInput value={customer.profileData.companyName} type="text" label="Company Name" variant="standard" fullWidth/>
        </MDBox>

        <MDBox mb={2}>
          <MDInput type="email" value={customer.profileData.email} label="Email" variant="standard" fullWidth/>
        </MDBox>

        <MDBox mb={2}>
          <MDInput type="number" value={customer.profileData.contactNumber} label="Contact Number" variant="standard" fullWidth/>
        </MDBox>

        <MDBox mb={2}>
          <MDInput type="number" value={customer.profileData.creditCardNumber} label="Credit Card Number" variant="standard" fullWidth/>
        </MDBox>

        <MDBox mb={2} display="flex">
          <MDBox mr={1} width="50%">
            <MDInput
              type="number"
              value={customer.profileData.expiration}
              label="Expiration"
              variant="standard"
              fullWidth
            />
          </MDBox>
          <MDBox ml={1} width="50%">
            <MDInput
              value={customer.profileData.cvv}
              type="number"
              label="Cvv"
              variant="standard"
              fullWidth
            />
          </MDBox>
        </MDBox>

        <MDBox mb={2}>
          <MDInput
            type="text"
            value={customer.profileData.address}
            label="Address"
            variant="standard"
            fullWidth
          />
        </MDBox>

        <MDBox mb={2}>
          <MDInput value={customer.profileData.address1}
            type="text"
            label="Address2 (optional)"
            variant="standard"
            fullWidth
          />
        </MDBox>

        <MDBox mb={2} display="flex" flexDirection="row">
          <MDBox mr={1}>
            <MDInput
              type="text"
              value={customer.profileData.city}
              label="City"
              variant="standard"
              fullWidth
            />
          </MDBox>

          <MDBox ml={1}>
            <MDInput
              type="text"
              value={customer.profileData.state}
              label="State"
              variant="standard"
              fullWidth
            />
          </MDBox>

          <MDBox ml={2}>
            <MDInput
              type="number"
              value={customer.profileData.zipcode}
              label="Zipcode"
              variant="standard"
              fullWidth
            />
          </MDBox>
        </MDBox>
      </MDBox>

      <MDBox ml={2} width="30%" style={{borderLeft:'0.5px solid gray',borderRight:'0.5px solid gray',paddingLeft:'5px',paddingRight:'5px'}}>
        <Typography>{"10Dlc info"}</Typography>
        <MDBox>
            <Typography margin={'8px'} fontSize={'16px'}>{"Would you like to use your company\'s existing number in BLIQ?"}</Typography>
            <FormControl fullWidth sx={{marginRight:'8px'}}>
                <InputLabel id="number-method">Select an option</InputLabel>
                <Select
                    labelId="number-method"
                    id="select-number-method"
                    value={customer.dlcData.existingNumber}
                    sx={{
                        height:'40px',
                        marginRight:'8px'
                    }}
                    label="Select an option"
                >
                    <MenuItem value={"yes"}>{"Yes! Please do."}</MenuItem>
                    <MenuItem value={"no"}>{"No. Please assign us a new number to use in BLIQ."}</MenuItem>
                </Select>
            </FormControl>
        </MDBox>

        <MDBox display="flex" flexDirection="columns">
            {customer.dlcData.numbers.map((item,index) => <MDInput sx={{margin:'8px'}} value={item} key={index} type="number" label="Number" variant="standard" fullWidth/>)}
        </MDBox>

        <MDBox mb={2} mt={1}>
            <MDInput
            type="text"
            label="10DLC Company Name"
            variant="standard"
            value={customer.dlcData.dlcName}
            fullWidth
            />
        </MDBox>

        <MDBox mb={2}>
            <MDInput
            type="text"
            value={customer.dlcData.dlcNumber}
            label="10 DLC Company Number"
            variant="standard"
            fullWidth
            />
        </MDBox>

        <MDBox>
            <Typography margin={'8px'} fontSize={'16px'}>{"Does your company need HIPAA Compliance?"}</Typography>
            <FormControl fullWidth sx={{marginRight:'8px'}}>
                <InputLabel id="hipaa-method">Select an option</InputLabel>
                <Select
                    labelId="hipaa-method"
                    id="hipaa-number-method"
                    value={customer.dlcData.hipaaCompliance}
                    sx={{
                        height:'40px',
                        marginRight:'8px'
                    }}
                    label="Select an option"
                >
                    <MenuItem value={"yes"}>YES</MenuItem>
                    <MenuItem value={"no"}>NO</MenuItem>
                </Select>
            </FormControl>
            <Typography sx={{marginTop:'8px'}} fontWeight={"600"} fontSize={"12px"}>{"If yes, before sending a sms to a recipient, we would prompt them to consent to communication via SMS."}</Typography>
        </MDBox>
      </MDBox>

      <MDBox display="flex" width="20%" flexDirection="column" ml={2}>
        <Typography>{"Users info"}</Typography>
        {customer.users.map(item => <UserItem user={item}/>)}
      </MDBox>
    </MDBox>
  );
};
export default DetailsForm;
