import { AlertTitle, Snackbar } from "@mui/material";
import React, { useEffect } from "react";
import MuiAlert from '@mui/material/Alert';
import { useMediaQuery } from "react-responsive";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

const AppSnackBar = ({showSnackBar,type,children,autoHideMs,heading,vertical,horizontal,icon = undefined}) => {
  
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
  const [open, setOpen] = React.useState(showSnackBar);
  const anchorOrigin = {
    vertical : isMobile ? 'top' : (vertical ? vertical : 'bottom'),
    horizontal : isMobile ? 'center' : (horizontal ? horizontal : 'left')
  }
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  useEffect(() => {
    setOpen(showSnackBar);
  },[showSnackBar]);

  return(
      <Snackbar 
        open={open} 
        autoHideDuration={autoHideMs?autoHideMs:null} 
        onClose={handleClose}
        anchorOrigin={anchorOrigin}
      >
        <Alert severity={type ? type : 'info'} icon={icon} sx={{ width: '100%' }}>
          <AlertTitle>{heading}</AlertTitle>
            {children}
        </Alert>
      </Snackbar>
  )
}
export default AppSnackBar;