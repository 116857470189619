import { BASE_URL } from "constants/Endpoints";
import fetch from "core/fetch";

export const getAutoReply = async(token) => {
    const response = await fetch(`${BASE_URL}/settings/autoreply`,{
        method : 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + token,
          },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data; 
}

// update email credentials
export const setEmailCred = async(changeData) => {
    const payload = {
        ...changeData,
        token : undefined
    }
    const response = await fetch(`${BASE_URL}/admin/email`,{
        method : 'POST',
        body : JSON.stringify(payload),
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + changeData.token,
          },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data; 
}

export const unscheduleEmailNotifications = async(notificationPayload) => {
    const payload = {
        ...notificationPayload,
        token: undefined
    }
    const response = await fetch(`${BASE_URL}/email/unschedule`,{
        method : 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + notificationPayload.token,
          },
        body : JSON.stringify(payload)  
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data; 
}

//// GET DLC CATEGORIES
export const getDLC = async(token) => {
    const response = await fetch(`${BASE_URL}/settings/dlc`,{
        method : 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + token,
          },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not call the endpoint.');
    }
    return data; 
}