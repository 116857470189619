import { FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, Radio, RadioGroup, Select } from "@mui/material";
import MDBox from "lib/components/MDBox";
import MDButton from "lib/components/MDButton";
import MDInput from "lib/components/MDInput";
import MDTypography from "lib/components/MDTypography";
import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import AppModal from "ui/AppModal";
import KeywordGroupMap from "./Keywords/KeywordGroupMap";
import { getPhoneList } from "api/phoneCredentials";
import useHttp from "hooks/use-http";
import { MAPPED_WITH } from "constants/AppConstants";

const AddKeyword = ({onClose,onAdded}) => {

    const [mapped, setMapped] = useState(MAPPED_WITH.AUTO_REPLY);

    const [error, setError] = useState('');
    const keywordRef = useRef();
    const responseRef = useRef();
    const user = useSelector(state => state.user); 
    const [selectedGroup,setSelectedGroup] = useState();
    const [selectedPhone,setSelectedPhone] = useState();

    const {sendRequest : fetchPhoneList, data : phoneList , status : fetchStatus} = useHttp(getPhoneList, true);

    const onPhoneChange = (e) => {
        const p = e.target.value;
        setSelectedPhone(p);
    }

    const onGroupSelected = (gp) => {
        setSelectedGroup(gp);
    }
    const onKeywordAddClick = useCallback(() => {
        let reply = '';
        if(mapped === MAPPED_WITH.AUTO_REPLY){
            reply = responseRef.current.value;
            if(reply.trim().length === 0){
                setError("please enter corresponding response.");    
                return;
            }
        }
        const keyword = keywordRef.current.value;
        if(keyword.trim().length === 0){
            setError("Keyword can not be empty.");
            return;
        }
        const payload = {
            reply,keyword : keyword.toLowerCase(),token : user.token,
        }
        if(selectedGroup){
            payload.group = {
                ...selectedGroup
            }
        }
        if(selectedPhone && mapped === MAPPED_WITH.PAYMENT_ASSOCIATED){
            payload.phone = {
                ...selectedPhone
            }
            payload.payment_associated = true;
        }
        onAdded(payload);
    },[mapped,selectedPhone,selectedGroup,onAdded,keywordRef,user]);

    useEffect(() => {
        if(user)
            fetchPhoneList({token : user.token,companyId : user.company?.company_id,userId : user.id});
    },[user]);

    return (
        <>
            <AppModal
                rootStyle = {
                    {
                      "&.MuiModal-root":{
                        width:'50%',
                        left: '50%'
                      }
                    }
                }
                customStyle={{ 
                    width: "100%",
                    height: "100%",
                }}
                onModalClose={() => onClose()}
                heading={"Create keyword and corresponding response"}>
                <MDBox
                    width="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    flexDirection="column"
                >
                    <MDBox
                        width="100%"
                        display="flex"
                        mt={2}
                        alignItems="center"
                        justifyContent="center"
                    >
                        <MDInput
                            fullWidth
                            type="text"
                            onChange={(e) => {
                                const tmpInput = e.target.value;
                                if (
                                tmpInput === "info" ||
                                tmpInput === "INFO" ||
                                tmpInput === "HELP" ||
                                tmpInput === "help"
                                ) {
                                responseRef.current.value = `Hi! This is the texting line for ${
                                    user?.company?.company_name
                                }. For any further questions, please call ${
                                    user?.company?.company_contact
                                    ? user?.company?.company_contact
                                    : "{number}"
                                }.`;
                                }
                            }}
                            label="Keyword"
                            inputRef={keywordRef}
                        />
                    </MDBox>
                    <MDBox ml={1} width="100%" display="flex" justifyContent="start">
                        <FormGroup>
                            <FormControl>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    onChange={(e) => {
                                        setMapped(e.target.value)
                                    }}
                                    value={mapped}
                                    >
                                    <FormControlLabel
                                        value={MAPPED_WITH.AUTO_REPLY}
                                        control={<Radio />}
                                        label="Auto reply"
                                    />
                                    <FormControlLabel
                                        value={MAPPED_WITH.BROADCAST_GROUP}
                                        control={<Radio />}
                                        label="Associate with broadcast group"
                                    />
                                    <FormControlLabel
                                        value={MAPPED_WITH.PAYMENT_ASSOCIATED}
                                        control={<Radio />}
                                        label="Associate with payment"
                                    />                                
                                </RadioGroup>
                            </FormControl>
                        </FormGroup>
                    </MDBox>
                    { MAPPED_WITH.AUTO_REPLY === mapped && <MDBox
                        width="100%"
                        display="flex"
                        style={{ marginTop: "12px" }}
                        alignItems="center"
                        justifyContent="center"
                    >
                        <MDInput
                        fullWidth
                        inputRef={responseRef}
                        type="text"
                        label="Response"
                        multiline
                        />
                    </MDBox>}
                    <MDBox width="100%" display="flex" justifyContent="flex-start">
                        <MDTypography
                            variant="overline"
                            fontWeight="medium"
                            color="error"
                        >
                            {error}
                        </MDTypography>
                    </MDBox>
                    {mapped === MAPPED_WITH.BROADCAST_GROUP && <KeywordGroupMap phoneList={phoneList} selectedGroup={selectedGroup} onGroupSelected={onGroupSelected}/>}
                    {mapped === MAPPED_WITH.PAYMENT_ASSOCIATED && 
                    <MDBox width="100%">
                        <FormControl fullWidth sx={{marginRight:'8px'}}>
                            <InputLabel id="phone-label">Choose number</InputLabel>
                            <Select
                                labelId="phone-label"
                                id="choose-phone"
                                sx={{
                                    height:'40px',
                                    marginRight:'8px'
                                }}
                                label="Choose number"
                                onChange={onPhoneChange}
                            >
                                { phoneList ? phoneList?.map(item => <MenuItem key={item.phoneId} value={item}>{item.phone}</MenuItem>) : <MenuItem>No phone available</MenuItem>}
                            </Select>                    
                        </FormControl>
                    </MDBox>
                }
                    <MDBox
                        style={{ marginTop: "12px" }}
                        display="flex"
                        justifyContent="start"
                        width="100%"
                    >
                        <MDButton
                            onClick={onKeywordAddClick}
                            size={"medium"}
                            color="info"
                            width="300px"
                            variant="contained"
                        >
                            Create
                        </MDButton>
                    </MDBox>
                </MDBox>
            </AppModal>
        </>
    );
}
export default AddKeyword;