import { Button } from "@mui/material";
import ChooseFileGeneric from "components/CommonUI/ChooseFileGeneric";
import AppConstants from "constants/AppConstants";
import MDBox from "lib/components/MDBox";
import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { BulkAPI } from "services/BulkAPI";
import { loaderAction } from "store/loader-slice";
import AppModal from "ui/AppModal";

const UploadBulkUser = ({onCancel,onUploaded}) => {

    const dispatch = useDispatch();

    const token = useSelector(state => state.user.token);

    const[bulkFile,setBulkFile] = useState(undefined);

    const onFileSelected = (file) => {
        setBulkFile(file);
    }
    const onSubmitHandler = useCallback(() => {
        dispatch(loaderAction.show());
        if(bulkFile){
            const formData = new FormData();
            formData.append('file',bulkFile);
            BulkAPI.bulkUploadUser({formData,token}).then(res => {
                setBulkFile(undefined);
                dispatch(loaderAction.hide());
                onUploaded(res.data);
                toast.success("Users uploaded successfully.");
            }).catch(e => {
                dispatch(loaderAction.hide());
                toast.error(e?.response?.data?.message);
            })
        }
    },[bulkFile,token])

    return(
        <AppModal
            onModalClose={onCancel}
            heading={AppConstants.CHOOSE_EXCEL}
        >
            <ChooseFileGeneric onFileSelected={onFileSelected} templateTooltipMsg="Download template" showTemplate={true} templateUrl= {AppConstants.BULK_USER_TEMPLATE} />
            <MDBox display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                {(bulkFile) && <Button
                    onClick={onSubmitHandler}
                    size={"medium"}
                    variant="contained"
                    sx={{borderRadius: "0px",color: "white!important",maxWidth:'200px!important'}}
                >
                    Submit 
                </Button>}
            </MDBox> 
        </AppModal>        
    )
}
export default UploadBulkUser;