import DashboardLayout from "lib/layouts/LayoutContainers/DashboardLayout";

// Material Dashboard 2 React components
import { useCallback, useEffect, useState } from "react";
import AppModal from "ui/AppModal";
import { useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import UserList from "../../components/User/UserList";
import DashboardNavbar from "lib/layouts/Navbars/DashboardNavbar";
import UserPhoneAssignFlowWrapper from "components/Phone/UserPhoneAssignFlowWrapper";
import { UserAPI } from "services/UserAPI";
import { toast } from "react-toastify";
import ArrowAddBtn from "ui/ArrowAddBtn";
import NotificationItem from "lib/layouts/Items/NotificationItem";
import UploadBulkUser from "components/User/UploadBulkUser";
import UploadReport from "components/CommonUI/UploadReport";

const CREATE_TYPE = {
  SINGLE: "single",
  BULK: "bulk",
  NONE: "none",
};
const USER_BULK_REPORT_COLUMN = [
  { id: 1, key: "email", label: "Email" },
  { id: 2, key: "status", label: "Status" },
  { id: 3, key: "reason", label: "Reason" },
];

const Users = () => {
  const [userUploadReport, setUserUploadReport] = useState(undefined);

  const token = useSelector((state) => state.user.token);
  const company = useSelector((state) => state.user.company);
  const userId = useSelector((state) => state.user.id);
  const [users, setUsers] = useState([]);
  const [action, setAction] = useState(CREATE_TYPE.NONE);

  const onCreateUserHandler = (res) => {
    if (res) {
      setUsers((prev) => [res, ...prev]);
    }
  };

  const onUploaded = (res) => {
    setAction(CREATE_TYPE.NONE);
    if (res) {
      const allUsers = res.filter((itm) => itm.status === "success");
      const report = res.map((itm, idx) => {
        return {
          reason: itm.status === "failed" ? itm?.reason : "NA",
          email: itm.email,
          status: itm.status,
        };
      });
      setUserUploadReport({ rows: report, columns: USER_BULK_REPORT_COLUMN });
      setUsers((prev) => [...allUsers, ...prev]);
    }
  };

  const onUploadCancel = () => {
    setAction(CREATE_TYPE.NONE);
  };

  const getUsersFn = useCallback(() => {
    if (company && userId) {
      const payload = {
        token,
        company_id: company?.company_id,
        user_id: userId,
      };
      UserAPI.getUsers(payload)
        .then((res) => {
          setUsers(res.data);
        })
        .catch((e) => {
          toast.error(e?.response?.data?.message);
        });
    }
  }, [company, userId, token]);

  useEffect(() => {
    if (company.company_id && userId) {
      getUsersFn();
    }
  }, [company.company_id, userId, getUsersFn]);

  // Preparing JSX with auto Menu close on option selection
  const MenuItemList = ({ handleMenuClose }) => (
    <>
      <NotificationItem
        onClick={() => {
          setAction(CREATE_TYPE.SINGLE);

          handleMenuClose();
        }}
        title={"Create user"}
      ></NotificationItem>
      <NotificationItem
        onClick={() => {
          setAction(CREATE_TYPE.BULK);
          handleMenuClose();
        }}
        title={"Upload users"}
      ></NotificationItem>
    </>
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ArrowAddBtn
        text={"create new user"}
        customStyle={{ marginBottom: "12px!important" }}
        menuItems={<MenuItemList />}
        onPopoverClose={() => setAction(CREATE_TYPE.NONE)}
      />
      {users && <UserList data={users} refetch={getUsersFn} />}
      {action === CREATE_TYPE.SINGLE && (
        <AppModal
          heading={"Create User"}
          onModalClose={() => {
            setAction(CREATE_TYPE.NONE);
          }}
          rootStyle={{
            "&.MuiModal-root": {
              width: "40%",
              left: "60%",
            },
          }}
          customStyle={{
            width: "100%",
            height: "100%",
          }}
        >
          <UserPhoneAssignFlowWrapper
            setAction={setAction}
            onCreateUserHandler={onCreateUserHandler}
          />
        </AppModal>
      )}
      {action === CREATE_TYPE.BULK && (
        <UploadBulkUser onUploaded={onUploaded} onCancel={onUploadCancel} />
      )}
      {Boolean(userUploadReport) && (
        <AppModal
          rootStyle={{
            "&.MuiModal-root": {
              width: "90%",
              left: "10%",
            },
          }}
          customStyle={{
            width: "100%",
            height: "100%",
          }}
          onModalClose={() => setUserUploadReport(undefined)}
          heading={"User upload report"}
        >
          <UploadReport
            rows={userUploadReport.rows}
            columns={userUploadReport.columns}
          />
        </AppModal>
      )}
    </DashboardLayout>
  );
};
export default Users;
