import { Close } from "@mui/icons-material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import MDBox from "lib/components/MDBox";
import React, { useEffect } from "react";

import { useMaterialUIController, setMiniSidenav } from "lib/context";

const AppDialog = ({
  style,
  title,
  content,
  show,
  onClose,
  footer,
  showCloseOption = true,
  fullWidth = true,
  mediaModalClose = true,
}) => {
  ///// Dialog state
  const [open, setOpen] = React.useState(show);
  const [controller, dispatch] = useMaterialUIController();

  /// When dialog box is opened
  const handleClickOpen = () => {
    setOpen(true);
  };

  //// When dialog box is closed
  const handleClose = () => {
    console.log("media Close", mediaModalClose);
    setOpen(false);
    onClose();
    mediaModalClose && setMiniSidenav(dispatch, false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };
  useEffect(() => {
    setOpen(show);
  }, [show]);

  useEffect(() => {
    // Minimize the sidenav when the modal is opened
    if (open) {
      setMiniSidenav(dispatch, true);
    }
  }, [open, dispatch]);

  return (
    <>
      <Dialog
        style={{ ...style }}
        open={open}
        fullWidth={fullWidth}
        onClose={!showCloseOption ? handleClose : () => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <MDBox
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          pl={2}
          pr={2}
          pt={2}
        >
          {title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
          {showCloseOption && (
            <Close
              onClick={handleClose}
              style={{
                cursor: "pointer",
                padding: "4px",
                height: "28px",
                width: "28px",
              }}
            />
          )}
        </MDBox>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>{footer}</DialogActions>
      </Dialog>
    </>
  );
};
export default AppDialog;
