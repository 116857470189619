import DeleteIcon from "@mui/icons-material/Delete";
import RefreshIcon from "@mui/icons-material/Refresh";
import { styled, keyframes } from "@mui/material/styles";
import { FlexContainer } from "pages/Chat/ChatStyles";
import { Navbar } from "pages/Chat/ChatStyles";
import { Checkbox, Icon, Menu, Popover, Tooltip, Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import RecipientSearch from "../RecipientSearch";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { toast } from "react-toastify";
import { Close } from "@mui/icons-material";
import Swal from "sweetalert2";
import MarkChatUnreadIcon from "@mui/icons-material/MarkChatUnread";
import MarkChatReadIcon from "@mui/icons-material/MarkChatRead";
import DateRangeIcon from "@mui/icons-material/DateRange";
import CustomDateRangePicker from "components/util/CustomDateRangePicker";
import MarkunreadIcon from "@mui/icons-material/Markunread";
import NotificationItem from "lib/layouts/Items/NotificationItem";
import MenuIcon from "@mui/icons-material/Menu";
import GroupMenu from "components/GroupChat/GroupMenu";
import { chatVariablesAction } from "store/chat-variables-slice";
import { bulkAllChatExport } from "api/export";
import { buildExportArr } from "util/ChatNavUtil";
import useHttp from "hooks/use-http";
import { bulkChatDelete } from "api/export";
import { markAsRead } from "api/export";
import { markAsUnRead } from "api/export";
import { NavbarOuterWrapper } from "pages/Chat/ChatStyles";
import { SearchOuterWrapper } from "pages/Chat/ChatStyles";
import UserSettings from "../UserSettings";
import MDBox from "lib/components/MDBox";
import { chatAction } from "store/chat-slice";
import { useCallback } from "react";
import { messageAction } from "store/message-slice";

const ChatNavBar = ({ onEventPerform, reloadRecipients }) => {
  // console.log("Left chat nav bar");
  //// 0 --> default, 2 --> search

  const dispatch = useDispatch();
  const currentUserId = useSelector((state) => state.user.id);
  const [chatExport, setChatExport] = useState(false);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);
  const token = useSelector((state) => state.user.token);
  const [openMenu, setOpenMenu] = useState(false);
  const [selection, setSelection] = useState(0);
  const role = useSelector((state) => state.user.role);
  const activeCheckbox = useSelector(
    (state) => state.chatVariables.activeCheckbox
  );
  const isCheckboxSelected = useSelector(
    (state) => state.chatVariables.isCheckboxSelected
  );
  const allConversationSelected = useSelector(
    (state) => state.chatVariables.allConversationSelected
  );
  const currentActiveView = useSelector(
    (state) => state.chat.current_active_view
  );
  const currentPhone = useSelector((state) => state.chat.current_active_phone);
  const allPhones = useSelector((state) => state.userDetails.details.phones);
  const [chatboxSelected, setCheckboxSelected] = useState(
    allConversationSelected
  );
  const [exportDateRange, setExportDateRange] = useState();
  const [groupMenuTarget, setGroupMenuTarget] = useState();

  const updateCheckedMap = useCallback(
    (data) => {
      const newCheckedState = {};
      for (const item in activeCheckbox) {
        let messageIds = [];
        newCheckedState[item] = {
          selected: data,
          messageIds: messageIds,
        };
      }
      dispatch(chatVariablesAction.setIsCheckboxSelected(data));
      dispatch(chatVariablesAction.setActiveCheckbox(newCheckedState));
    },
    [activeCheckbox]
  );

  ////
  const { sendRequest: bulkChatExportFn } = useHttp(bulkAllChatExport, false);
  const {
    sendRequest: bulkChatDeleteFn,
    data: deleteBulkData,
    status: deleteBulkStatus,
    error: deleteBulkErr,
  } = useHttp(bulkChatDelete, false);
  const { sendRequest: markAsReadFn, status: readStatus } = useHttp(
    markAsRead,
    false
  );

  const { sendRequest: markAsUnReadFn, status: unReadStatus } = useHttp(
    markAsUnRead,
    false
  );

  const onCheckedEvent = (event) => {
    dispatch(
      chatVariablesAction.setAllConversationSelected(event.target.checked)
    );
    setCheckboxSelected(event.target.checked);
    updateCheckedMap(event.target.checked);
    event.target.checked ? setChatExport(true) : setChatExport(false);
  };

  const onUncheckedEvent = (event) => {
    setCheckboxSelected(false);
    updateCheckedMap(false);
  };

  const exportConversation = useCallback(
    (data) => {
      let phoneIds = [];
      if (currentPhone?.phoneId === -100000) {
        allPhones.map((_ap) => {
          phoneIds.push(_ap?.phoneId);
        });
      } else {
        phoneIds.push(currentPhone?.phoneId);
      }
      const exportArr = buildExportArr(activeCheckbox);
      let exportDatas = [];
      phoneIds.map((_p) => {
        exportDatas.push({
          export: exportArr,
          phone_id: _p,
          date_range: { start: data?.startDate, end: data?.endDate },
        });
      });
      bulkChatExportFn({ exportDatas, token, allPhones });
      dispatch(chatVariablesAction.setRightNavChecked(false));
      dispatch(chatVariablesAction.setIsCheckboxSelected(false));
      updateCheckedMap(false);
    },
    [currentPhone, dispatch, activeCheckbox]
  );

  const read = useCallback(() => {
    const exportArr = buildExportArr(activeCheckbox);
    const markReadData = {
      export: exportArr,
      phone_id: currentPhone?.phoneId,
      user_id: currentUserId,
    };
    markAsReadFn({ payload: markReadData, token });
    dispatch(chatVariablesAction.setIsCheckboxSelected(false));
    updateCheckedMap(false);
  }, [activeCheckbox]);

  const unRead = useCallback(() => {
    const exportArr = buildExportArr(activeCheckbox);
    const markUnReadData = {
      export: exportArr,
      phone_id: currentPhone?.phoneId,
    };
    dispatch(chatVariablesAction.setIsCheckboxSelected(false));
    markAsUnReadFn({ payload: markUnReadData, token });
    updateCheckedMap(false);
  }, [activeCheckbox]);

  const deleteConversation = useCallback(() => {
    const exportArr = buildExportArr(activeCheckbox);
    const DeleteData = {
      export: exportArr,
      phone_id: currentPhone?.phoneId,
    };
    dispatch(chatVariablesAction.setIsCheckboxSelected(false));
    bulkChatDeleteFn({ payload: DeleteData, token });
    updateCheckedMap(false);
  }, [activeCheckbox]);

  const handleGroupMenuOptions = (event) => {
    setGroupMenuTarget(event);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleDateRangeClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onRangeApply = (range) => {
    setAnchorEl(null);
    setExportDateRange(range);
    onExport(range);
  };

  const onRecipientAdded = (data) => {
    setSelection(0);
  };

  const onDeleteHandler = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteConversation();
        setSelection(0);
        setChatExport(false);
        setCheckboxSelected(false);
      }
    });
  };

  const onExport = useCallback(
    (range = undefined) => {
      exportConversation(range ? range : exportDateRange);
      setSelection(0);
      setChatExport(false);
      setCheckboxSelected(false);
      setExportDateRange(undefined);
    },
    [exportDateRange, activeCheckbox]
  );

  const onSearchEvent = (input) => {
    onEventPerform("RECIPIENT_NAV_SEARCH", input);
  };

  const onReadClickHandler = () => {
    setOpenMenu(false);
    setChatExport(false);
    setSelection(0);
    setCheckboxSelected(false);
    read();
  };

  const onUnReadClickHandler = () => {
    setOpenMenu(false);
    setChatExport(false);
    setSelection(0);
    setCheckboxSelected(false);
    unRead();
  };
  const groupMenuEventHandler = (action, data) => {
    if (action === "GROUP_MENU_CLOSED") {
      setGroupMenuTarget(null);
    } else {
      const d = { data, activePhone: currentPhone };
      onEventPerform(action, d);
      setGroupMenuTarget(null);
    }
  };
  ///// EFFECTS

  useEffect(() => {
    setChatExport(isCheckboxSelected);
  }, [isCheckboxSelected]);

  useEffect(() => {
    if (deleteBulkStatus === "completed") {
      if (deleteBulkData) {
        dispatch(
          chatAction.deleteRecipientConversation({ ids: deleteBulkData })
        );
        dispatch(
          messageAction.deleteRecipientConversation({ ids: deleteBulkData })
        );
        setCheckboxSelected(false);
        dispatch(chatVariablesAction.setIsCheckboxSelected(false));
      } else {
        toast.error("something went wrong, please try again..");
        setCheckboxSelected(false);
      }
    }
  }, [deleteBulkStatus, deleteBulkErr, deleteBulkData]);

  useEffect(() => {
    if (readStatus === "completed") {
      reloadRecipients();
    }
  }, [readStatus]);

  useEffect(() => {
    if (unReadStatus === "completed") {
      reloadRecipients();
    }
  }, [unReadStatus]);

  const renderUnread = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem
        onClick={onReadClickHandler}
        icon={
          <Icon>
            <MarkChatReadIcon />
          </Icon>
        }
        title="Mark as read"
      />
      <NotificationItem
        onClick={onUnReadClickHandler}
        icon={
          <Icon>
            <MarkChatUnreadIcon />
          </Icon>
        }
        title="Mark as unread"
      />
    </Menu>
  );

  return (
    <NavbarOuterWrapper>
      <Navbar
        padding="13px 10px"
        bgColor="#f5f5f5"
        borderBottom="1px solid #f5f5f5"
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        {currentPhone && (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <UserSettings onEventPerform={onEventPerform} />

              <Tooltip title="Sync">
                <RefreshIcon
                  onClick={() => {
                    dispatch(
                      chatVariablesAction.setRecipientContainerLoader(true)
                    );
                    dispatch(chatAction.resetChats());
                    dispatch(messageAction.resetChats());
                    reloadRecipients();
                  }}
                  fontSize="medium"
                  className="navbar-icon refresh-icon"
                  style={{ cursor: "pointer", marginLeft: "16px" }}
                />
              </Tooltip>
            </Box>
            <MDBox display="flex" justifyContent="flex-end">
              {selection === 0 && (
                <div>
                  <FlexContainer spacing="15px">
                    {chatExport && (
                      <>
                        <Tooltip title="Delete the selected">
                          <DeleteIcon
                            onClick={onDeleteHandler}
                            fontSize="medium"
                            className="navbar-icon"
                          />
                        </Tooltip>
                        {currentActiveView === "NORMAL_VIEW" && (
                          <Tooltip title="Mark as read/unread.">
                            <MarkunreadIcon
                              onClick={handleOpenMenu}
                              fontSize="medium"
                              className="navbar-icon"
                            />
                          </Tooltip>
                        )}
                        {renderUnread()}
                        <Tooltip title="Export the selected recipient conversations.">
                          <FileDownloadIcon
                            onClick={onExport}
                            fontSize="medium"
                            className="navbar-icon"
                          />
                        </Tooltip>
                        <Tooltip title="Select date range for export.">
                          <DateRangeIcon
                            onClick={handleDateRangeClick}
                            fontSize="medium"
                            className="navbar-icon"
                          />
                        </Tooltip>
                        <Popover
                          id={id}
                          open={open}
                          anchorEl={anchorEl}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                          sx={{
                            "& .MuiPopover-paper": {
                              backgroundColor: "white!important",
                            },
                          }}
                        >
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <CustomDateRangePicker
                              onCancel={() => setAnchorEl(null)}
                              onRangeApply={onRangeApply}
                            ></CustomDateRangePicker>
                          </div>
                        </Popover>
                      </>
                    )}
                    {currentActiveView !== "GROUP_VIEW" && isCheckboxSelected && (
                      <Tooltip title="Select/unselect all">
                        <div>
                          <Checkbox
                            onChange={onCheckedEvent}
                            checked={chatboxSelected}
                            className="navbar-checkbox"
                          />
                        </div>
                      </Tooltip>
                    )}
                    {isCheckboxSelected === true && (
                      <Tooltip title="Close">
                        <Close
                          onClick={onUncheckedEvent}
                          fontSize="medium"
                          className="navbar-icon"
                        />
                      </Tooltip>
                    )}
                    {!chatExport && currentActiveView === "GROUP_VIEW" && (
                      <MenuIcon
                        onClick={handleGroupMenuOptions}
                        fontSize="medium"
                        className="navbar-icon"
                      />
                    )}
                    {groupMenuTarget && (
                      <GroupMenu
                        popoverTarget={groupMenuTarget}
                        onActionPerformed={groupMenuEventHandler}
                        currentActivePhone={currentPhone}
                      />
                    )}
                  </FlexContainer>
                </div>
              )}
            </MDBox>
          </>
        )}
      </Navbar>
      <FlexContainer
        height="auto"
        justifyContent="space-between"
        paddingLeftRight="10px"
        className="full-width-searchbar"
      >
        <SearchOuterWrapper>
          <RecipientSearch />
        </SearchOuterWrapper>
      </FlexContainer>
    </NavbarOuterWrapper>
  );
};
export default React.memo(ChatNavBar);
