import ChatMainWrapper from "components/ChatPanelWrapper/ChatWrapper";
import { WEBSOCKET_EVENT_TYPE } from "constants/ChatConstants";
import { BLIP_WS_URL } from "constants/Endpoints";
import { COMPANY_ONLINE_STATUS } from "constants/WsConstants";
import useIevent from "hooks/IChat/use-ievent";
import useEvent from "hooks/use-event";
import { memo, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IChatAction } from "store/IChat/Ichat-slice";
import { IGroupAction } from "store/IChat/igroup-slice";
import { chatAction } from "store/chat-slice";
import { connectWebSocket } from "util/WebSocketUtility";
import { UserAPI } from "services/UserAPI";
import useApp from "hooks/use-app";
import { parseWSData } from "util/UtilityFunctions";
import { userAction } from "store/user-slice";
import { messageAction } from "store/message-slice";
import { appAction } from "store/app-slice";
import { userStatusService } from "services/Background";
import { stopUserStatusService } from "services/Background";
import { USER_QUEUE } from "constants/WsConstants";
import { USER_ANNOUNCEMENT } from "constants/WsConstants";

const header = {
    // 'auto-delete': true,
    // 'durable' : false
    // "x-ha-queues" : true,
    ack: 'client'
};

const Conversation = () => {

    const companyId = useSelector(state => state.user.company?.company_id);
    const profileId = useSelector(state => state.user.id);
    const token = useSelector(state => state.user.token);
    const stompClient = useSelector(state => state.app.stompClient);

    const {onEventReceive : onEventReceiveTele } = useEvent();
    const {onAppEvent} = useApp();
    const {onEventReceive} = useIevent();    
    const dispatch = useDispatch();

    const onAnnouncement = useCallback((res) => {
        console.log(res);
        const payload = parseWSData(res.body);
        if(payload) {
            onAppEvent(payload);
            res.ack();
        }
    },[onAppEvent])

    const onCompanyEvent = (res) => {
        const data = res.body;
        // Check if the data appears to be JSON
        if (data.startsWith('{') && data.endsWith('}')) {
          try {
            const payload = JSON.parse(data);
            // Process the payload
            if(payload.ws_type === WEBSOCKET_EVENT_TYPE.ONLINE_STATUS) {
                if(payload.userId === profileId) return;
                dispatch(IChatAction.updateRecipientStatus({ recipientId: payload.userId, online: payload.online }));
            }
            else if(payload.ws_type === WEBSOCKET_EVENT_TYPE.PHONE_NEW_MESSAGE) {
                console.log(payload)
                onEventReceiveTele(WEBSOCKET_EVENT_TYPE.PHONE_NEW_MESSAGE,payload.message);
            }
            else if(payload.ws_type === WEBSOCKET_EVENT_TYPE.NEW_TAG){
                console.log('WEBSOCKET_EVENT_TYPE.NEW_TAG',WEBSOCKET_EVENT_TYPE.NEW_TAG)
                dispatch(messageAction.newTag(payload.tag));
                // add to company existings....
                dispatch(appAction.newCompanyTag(payload.tag));
            }
            else if(payload.ws_type === WEBSOCKET_EVENT_TYPE.REMOVE_TAG){
                dispatch(messageAction.removeTag(payload.tag));
            }
            //FIXME:: make it phone specific events, means write in use-phone-status
            else if(payload.ws_type === WEBSOCKET_EVENT_TYPE.CONTACT_BLOCKED){
                dispatch(chatAction.blockContact(payload.id));
            }
            //FIXME:: make it phone specific events, means write in use-phone-status
            else if(payload.ws_type === WEBSOCKET_EVENT_TYPE.CONTACT_UNBLOCKED){
                const data = {
                    id : payload.id
                }
                dispatch(chatAction.unblockRecipients(data));
            }
          } catch (error) {
            console.error('Error parsing JSON:', error);
          }
        } else {
          console.error('Received data is not valid JSON:', data);
        }    
    }
    
    // Most part of these events in IC
    const onMineEvents = (res) => {
        const data = res.body;
        console.log(data);
        // Check if the data appears to be JSON
        if (data.startsWith('{') && data.endsWith('}')) {
            try {
                const payload = JSON.parse(data);
                // Process the payload
                if(payload.ws_type === WEBSOCKET_EVENT_TYPE.USER_TYPING) {
                    if(payload.groupChat){
                        dispatch(IGroupAction.updateEvent(payload));
                    }
                    else{
                        dispatch(IChatAction.updateEvent(payload));
                    }
                }
                
                else if(payload.ws_type === WEBSOCKET_EVENT_TYPE.NEW_MESSAGE){
                    console.log(payload);
                    onEventReceive(WEBSOCKET_EVENT_TYPE.NEW_MESSAGE,payload.message);
                }
                else if(payload.ws_type === WEBSOCKET_EVENT_TYPE.MESSAGE_ACK) {
                    console.log(payload);
                    onEventReceive(WEBSOCKET_EVENT_TYPE.MESSAGE_ACK,payload.acks);
                }
                else if(payload.ws_type === WEBSOCKET_EVENT_TYPE.ADDED_TO_GROUP) {
                    console.log(payload);
                    onEventReceive(WEBSOCKET_EVENT_TYPE.ADDED_TO_GROUP,payload);
                }
                else if(payload.ws_type === WEBSOCKET_EVENT_TYPE.CREDIT_UPDATE){
                    dispatch(userAction.setCredit(payload));
                }
            } catch (error) {
                console.error('Error parsing JSON:', error);
            }
        } else {
            console.error('Received data is not valid JSON:', data);
        }
    }
      
    useEffect(() => {
        if(profileId && token){
          UserAPI.getUserPendingNotifications({token,userId : profileId}).then(res => {
            console.log(res);
          }).catch(e => console.log(e));
        }
    },[profileId,token]);  
    
    useEffect(() => {
        if(profileId && companyId){
          // Once user is authenticated....
          connectWebSocket(profileId,companyId,BLIP_WS_URL,dispatch);
        }
    },[profileId,companyId,dispatch]);
    
    useEffect(() => {
        let intId = undefined;
        if(companyId && token){
          intId = userStatusService({companyId, token});
        }
        return () => {
          if(intId){
            stopUserStatusService(intId);
          }
        }
      },[token,companyId]);  
    
    useEffect(() => {
        let subscription;
        let subscribeToMe;
        let subscribeToAnnouncement;
        if(profileId && stompClient && companyId){
            subscription  = stompClient?.subscribe(COMPANY_ONLINE_STATUS(companyId),onCompanyEvent,{userId: profileId });
            subscribeToMe = stompClient?.subscribe(USER_QUEUE(profileId),onMineEvents,{userId: profileId });
            subscribeToAnnouncement = stompClient?.subscribe(USER_ANNOUNCEMENT(profileId),onAnnouncement,header);
        }
        return () => {
            if (subscription) {
              subscription?.unsubscribe({simpDestination : `${COMPANY_ONLINE_STATUS(companyId)}`});
            //   UtilAPI.markOffline({token,userId : profileId, destination: COMPANY_ONLINE_STATUS(companyId)}).then().catch(e => {});
            }
            if(subscribeToMe){
                subscribeToMe?.unsubscribe({simpDestination : `${USER_QUEUE(profileId)}`});
            }
            if(subscribeToAnnouncement){
                subscribeToAnnouncement?.unsubscribe();
            }
        }
    },[profileId,stompClient,companyId,onAnnouncement,onCompanyEvent]);
      
    useEffect(() => {
        return () => {
            stompClient?.disconnect();
            dispatch(appAction.updateStompClient(undefined));
        }
    },[dispatch,stompClient]);

    return(
        <ChatMainWrapper/>
    )
}
export default memo(Conversation);