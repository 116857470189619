import { Typography } from "@mui/material";
import { getCompanyPrivacyPolicyPublic } from "api/company";
import useHttp from "hooks/use-http";
import MDBox from "lib/components/MDBox";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const PrivacyPolicyPage = ({ template }) => {
  const [convertedContent, setConvertedContent] = useState(null);

  const { slug } = useParams();

  const { sendRequest, status, data, error } = useHttp(
    getCompanyPrivacyPolicyPublic,
    false
  );

  function createMarkup(html) {
    return {
      __html: html,
    };
  }

  useEffect(() => {
    setConvertedContent(template);
  }, [template]);

  useEffect(() => {
    if (slug) {
      sendRequest(slug);
    }
  }, [slug,sendRequest]);

  useEffect(() => {
    if(status === 'completed'){
      if(data){
        const html = data.privacyPolicy ? data.privacyPolicy : data.companyWidePolicy;
        setConvertedContent(html);
      }
    }
  },[status,data,error])


  return (
    <>
      <MDBox display="flex" flexDirection="column" sx={{background:'white',minHeight:'100vh'}}>
        <MDBox mt={4} display="flex" width="100%" justifyContent="center">
          <Typography variant="h3">Privacy Policy</Typography>
        </MDBox>
        <MDBox sx={{paddingLeft:'24px',paddingRight:'24px'}} m={4}>
          <div dangerouslySetInnerHTML={createMarkup(convertedContent)}></div>

        </MDBox>
      </MDBox>
    </>
  );
};
export default PrivacyPolicyPage;
