import { getAllCarrier, deleteCarrier } from "services/CarrierAPI"; // Import deleteCarrier
import AddCarrier from "components/Carrier/AddCarrier";
import CarrierList from "components/Carrier/CarrierList";
import useHttp from "hooks/use-http";
import DashboardLayout from "lib/layouts/LayoutContainers/DashboardLayout";
import DashboardNavbar from "lib/layouts/Navbars/DashboardNavbar";
import { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import AddButton from "ui/AddButton";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { loaderAction } from "store/loader-slice";

const ACTION = {
  EDIT: "edit",
  ADD: "add",
  NONE: "none",
};

const Carrier = () => {
  const [addCarrier, setAddCarrier] = useState(ACTION.NONE);
  const [carriers, setCarrier] = useState([]);
  const { sendRequest, data, status, error } = useHttp(getAllCarrier, false);
  const token = useSelector((state) => state.user.token);
  const dispatch = useDispatch();

  const onNewCarrierCreated = (res) => {
    setAddCarrier(ACTION.NONE);
    setCarrier((prev) => [res, ...prev]);
  };

  const onEditClick = (c) => {
    // Edit logic here
  };

  const onDeleteHandler = useCallback(
    (id) => {
      Swal.fire({
        title: "Are you sure you want to delete?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        customClass: {
          container: {
            zIndex: "10000 !important",
          },
        },
      }).then((result) => {
        if (result.isConfirmed) {
          const carrierToDelete = carriers.find((c) => c.id === id);
          const filteredCarriers = carriers.filter((c) => c.id !== id);

          dispatch(loaderAction.show());

          deleteCarrier(id, token)
            .then((res) => {
              dispatch(loaderAction.hide());
              setCarrier(filteredCarriers); // Update the state
              toast.success("Carrier removed successfully.");
            })
            .catch((e) => {
              dispatch(loaderAction.hide());
              toast.error("Something went wrong, please try again.");
            });
        }
      });
    },
    [token, carriers, dispatch]
  );

  useEffect(() => {
    sendRequest(token);
  }, [sendRequest, token]);

  useEffect(() => {
    if (status === "completed" && data) {
      setCarrier(data);
    }
  }, [data, status, error]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {addCarrier === ACTION.ADD && (
        <AddCarrier
          onCreated={onNewCarrierCreated}
          onBack={() => setAddCarrier(ACTION.NONE)}
        />
      )}
      {addCarrier === ACTION.NONE && (
        <>
          <AddButton
            onClick={() => {
              setAddCarrier(ACTION.ADD);
            }}
            text={"Add carrier"}
          />
          <CarrierList
            data={carriers}
            onEditClick={onEditClick}
            onDelete={onDeleteHandler}
          />
        </>
      )}
    </DashboardLayout>
  );
};

export default Carrier;
