import { polyfillCountryFlagEmojis } from "country-flag-emoji-polyfill";
// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
// Material Dashboard 2 React themes
import Login from "pages/Authentication/Login";
import theme from "assets/theme";
import {Routes, Route, Navigate} from 'react-router-dom';
import Admin from "pages/Admin/Admin";
import {useDispatch, useSelector} from 'react-redux';
import Loader from "ui/Loader";
import NotFound from "pages/NotFound";
import Register from "pages/Authentication/Register";
import ForgotPassword from "pages/Authentication/ForgotPassword";
import React, { useEffect } from "react";
import CardKnoxProcessor from "components/Payments/CardKnoxProcessor";
import useHttp from "hooks/use-http";
import { getDLC } from "api/settings";
import { appAction } from "store/app-slice";
import PrivacyPolicyPage from "pages/PrivacyPolicyPage";
import IFrame from "pages/IFrame";
import ResetPasswordPage from "pages/Authentication/ResetPasswordPage";
import { userAction } from "store/user-slice";
import ActionSnackbar from "components/ActionSnackbar";
import Conversation from "pages/Conversation";
import SecondFactorAuth from "pages/2FA/SecondFactorAuth";
import AppModal from "ui/AppModal";
import AppSnackBar from "ui/AppSnackBar";
import DonationPagePreview from "components/Crowdfunding/DonationPagePreview";
polyfillCountryFlagEmojis();

function App() {
  const role = useSelector(state => state.user.role);
  const token = useSelector(state => state.user.token);
  const isAuthenticated = useSelector(state => state.user.isLoggedIn);
  const showLoader = useSelector(state => state.loader.showLoader);
  const appEvent = useSelector(state => state.app.newAnnouncement);
  const dispatch = useDispatch();
  const {sendRequest : getDlcFn, data : dlcCategory , error, status : fetchDlcStatus} = useHttp(getDLC,false);
  const snackbar = useSelector(state => state.app.snackbar)
  
  //// Redirect to the user if logged in on the bases of the role
  const getDestination = () => { 
    if(role === 'USER'){
      return "/conversations";
    }
    else if(role === 'ADMIN' || role === 'SUPER_ADMIN'){
      return "/admin/dashboard";
    }
  }

  useEffect(() => {
    const blipUserData = localStorage.getItem("blip_user_data");
    if(blipUserData){
      //TODO: call backend to verify that token is valid..
      const user = JSON.parse(blipUserData);
      dispatch(userAction.login(user));
    }
  },[dispatch]);

  useEffect(() => {
    if(token)
      getDlcFn(token);
  },[token,getDlcFn])

  useEffect(() => {
    if(fetchDlcStatus === 'completed'){
      if(dlcCategory)
        dispatch(appAction.setDlcCategories(dlcCategory));
    }
  },[dispatch,fetchDlcStatus,dlcCategory,error]);

  return (
    <>
      {showLoader && <Loader/>}
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Routes>
          <Route path='/' element={<><Navigate to='/login' replace /></>}/>
          <Route path='/donate/:campaignName/:fundraiserId' element={<CardKnoxProcessor/>}/>
          <Route path='/donate/:company/crowdfunding/preview' element={<DonationPagePreview/>}/>
          <Route path='/t&c/:slug' element={<PrivacyPolicyPage/>}/>
          <Route path="/forgot/password" element={<ForgotPassword/>}/>
          <Route path={"/company/signup/:planName"} element={<IFrame/>}/>
          <Route path={"/reset-password"} element={<ResetPasswordPage/>}/>
          <Route path='/login'  element={!isAuthenticated ? <Login/> : <Navigate to={getDestination()} replace/> } />
          <Route path="/2FA/authenticate" element={<SecondFactorAuth/>} />
          <Route path="/admin/register" element= { !isAuthenticated ? <Register/> : <Navigate to={getDestination()} replace />} />
          {isAuthenticated &&(role === 'ADMIN' || role === 'SUPER_ADMIN') && <Route path='/admin/*'  element={<Admin/>}/>}
          {isAuthenticated && role === 'USER' && <Route path='/conversations'  element={<Conversation/>}/>}
          <Route path="/404" element={<NotFound/>}/>
          <Route path="*" element={ <Navigate to="/login" replace />}/>
        </Routes>
        <ActionSnackbar />
        {snackbar && <AppSnackBar icon={snackbar.icon} horizontal={snackbar ? snackbar.horizontal : undefined} vertical={snackbar ? snackbar.vertical : undefined} showSnackBar={snackbar.show} heading={snackbar.heading} type={snackbar.type}>
          {snackbar.content}
        </AppSnackBar>}         
        {isAuthenticated && Boolean(appEvent) && 
          <AppModal
            onModalClose = {() => {}}
            heading={" "}
            closeOnClick={false}
          >
            {appEvent}
          </AppModal>
        }        
      </ThemeProvider>
    </>
  );
}

export default App;
