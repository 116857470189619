import { createSlice } from "@reduxjs/toolkit";
import { WEBSOCKET_EVENT_TYPE } from "constants/ChatConstants";
import { updateMessage_ } from "util/IChat/IMessageUtil";

const initialChatStates = {
    totalUnreadDmCount : 0,
    conversation : {},
    chats : [],
    currentActiveChat : undefined,
    fetched : false,
};

const IChatSlice = createSlice({
    name: "Ichat",
    initialState: initialChatStates,
    reducers : {

        setTotalUnreadGroupCount : (state,action) => {
            const {count} = action.payload;
            const oldCount = state.totalUnreadGroupCount;
            state.totalUnreadGroupCount = oldCount + count;
        },

        addChat : (state,action) => {
           state.chats.unshift(action.payload); 
        },
        
        updateRecipientStatus: (state, action) => {
            const { recipientId, online } = action.payload;
            const chatIndex = state.chats.findIndex(chat => chat.recipientId === recipientId);
            if (chatIndex !== -1) {
              state.chats[chatIndex].online = online;
            }
        },

        scheduledRecipientStatusUpdate : (state,action) => {
            const users = action.payload;
            try{
                if(state.chats?.length > 0 && users?.length > 0){
                    const map = {};
                    for(const u of users){
                        map[u.userId] = u;
                    }
                    for(let i = 0; i < state.chats.length; i++){
                        const currChat = state.chats[i];
                        const tmpVal = map[currChat.recipientId];
                        if(tmpVal){
                            state.chats[i].online = tmpVal?.online;
                        }
                    }
                }
            }catch(e){
                console.log(e);
            }
        },

        concateChat : (state, action) => {
            state.chats = state.chats.concat(action.payload);
        },
        updateCurrentActiveChat :  (state,action) => {
            state.currentActiveChat = action.payload;
        },
        resetChat : (state,action) => {
            state.chats = action.payload;
        },

        addMessage: (state, action) => {
            const { chatId, messages } = action.payload;
            if (!state.conversation[chatId]) {
              state.conversation[chatId] = [];
            }
            state.conversation[chatId] = [...state.conversation[chatId], ...messages];

        },
        updateChatAndMessage : (state,action) => {
            //chat and message created simultaneously.
            const { message } = action.payload;
            const chatId = message.chatId;
            const latestMessage = {
                messageId: message.messageId,
                text : message.text,
                createdAt : new Date(),
                type : 'text' 
            }
            
            const recipient = message.recipients.find(item => item.userId !== message.senderId);
            // const me = message.recipients.find(itm => itm.userId === message.senderId);

            // use chatTempId to update the chat
            const chatIndex = state.chats.findIndex(c => c.tempChatId === message.tempChatId);
            if (chatIndex !== -1) {
                state.chats[chatIndex].chatId = chatId;
                state.chats[chatIndex].newChat = false;
                state.chats[chatIndex].participants = [recipient];
                state.chats[chatIndex].participants[0].chatId = chatId;
                state.chats[chatIndex].latestMessage = latestMessage;
            }
            const messages = state.conversation[message.tempChatId];
            const newMessages = updateMessage_(messages,message);
            state.conversation[chatId] = [...newMessages]

            //update current active chat values
            state.currentActiveChat = {...state.currentActiveChat,chatId : chatId, newChat : false}
            delete state.conversation[message.tempChatId]; 
        },

        updateMessage : (state,action) => {
            const {chatId, message } = action.payload;
            
            const latestMessage = {
                messageId: message.messageId,
                text : message.text,
                createdAt : new Date(),
                type : 'text' 
            }
            // If state.conversation[chatId] undefined means, messages never fetch, in that scenario ignore message update.
            if(state.conversation[chatId]) {
                const messages = state.conversation[chatId];
                const newMessages = updateMessage_(messages,message);
                state.conversation[chatId] = [...newMessages];
            }
            const chatIndex = state.chats.findIndex(c => c.chatId === chatId);
            if (chatIndex !== -1) {
                state.chats[chatIndex].latestMessage = latestMessage;
            }

            if(chatIndex !== -1 && message.senderId !== state.chats[chatIndex].meId) {
                if(state.chats[chatIndex].unreadCount === 0){
                    const old = state.totalUnreadDmCount;
                    state.totalUnreadDmCount = old + 1;
                }
                state.chats[chatIndex].unreadCount = state.chats[chatIndex].unreadCount + 1; 
                
            }
        },

        addForwardedMessage : (state,action) => {
            const {message} = action.payload;
            const latestMessage = {
                messageId: message.messageId,
                text : message.text,
                createdAt : new Date(),
                type : 'text' 
            }
            state.conversation[message.chatId].push(message);
            const chatIndex = state.chats.findIndex(c => c.chatId === message.chatId);
            if (chatIndex !== -1) {
                state.chats[chatIndex].latestMessage = latestMessage;
            }
        },
        updateMessageAck : (state,action) => {
            const payload = action.payload;
            const chatIndex = state.chats.findIndex(c => c.chatId === payload.chatId);
            if(chatIndex !== -1) {
                state.conversation[payload.chatId].forEach(message => {
                    payload?.messageIds?.forEach(m => {
                        if(message.messageId === m){
                            message.status = payload.status;
                        }
                    })
                });
            }
        },

        updateEvent : (state,action) => {
            const payload = action.payload;
            const chatIndex = state.chats.findIndex(c => c.chatId === payload.chatId);
            if (chatIndex !== -1) {
                state.chats[chatIndex].iEvent = payload.status === 'typing' ? payload : undefined;
            }
            if(payload.type === WEBSOCKET_EVENT_TYPE.USER_TYPING && state.currentActiveChat){
                state.currentActiveChat = {...state.currentActiveChat, iEvent : payload.status === 'typing' ? payload : undefined}
            }
        },
        toggleMute : (state,action) => {
            const {chatId,muted} = action.payload;
            const chatIndex = state.chats.findIndex(c => c.chatId === chatId);
            if (chatIndex !== -1) {
                state.chats[chatIndex].muted = muted;
            }
        },

        // Will be called by end-user when new chat is added...
        addNewChatWithMsg : (state,action) => {
            const { message } = action.payload;
            const chatId = message.chatId;
            const latestM = {
                text : message.text,
                messageId : message.messageId,
                type : message.type,
                createdAt : message?.createdAt,
                updatedAt : message?.updatedAt
            }
            const recipient = message.recipients.find(item => item.userId === message.senderId);
            const me = message.recipients.find(itm => itm.userId !== message.senderId);
            const tmp = {
                chatId : message.chatId,
                name : recipient?.fullName,
                recipientId : message.senderId,
                online : true,
                iEvent : undefined,
                groupChat : false,
                muted : false,
                meParticipantId : me.userId,
                unreadCount :  1, 
                participants : [{
                    participantId : recipient?.id,
                    profilePic : recipient?.profilePic,
                    fullName : recipient?.fullName,
                    userId : recipient?.userId,
                    email : recipient?.email,
                    contactNumber : recipient?.contactNumber,
                    company : recipient?.company,
                    chatId : message?.chatId,
                }],
                latestMessage : latestM
            }
            state.chats.unshift(tmp);
            if (!state.conversation[chatId]) {
                state.conversation[chatId] = [message];
            };
        },

        updateReplyCounts : (state,action) => {
            const {message,chatId} = action.payload;
            const chatIndex = state.chats.findIndex(c => c.chatId === chatId);
            if(chatIndex !== -1){
                const messages = state.conversation[chatId];
                const messageIdx = messages.findIndex(itm => itm.messageId === message.parentMessage.messageId);
                if(messageIdx !== -1){
                    const newCount = state.conversation[chatId][messageIdx].repliesCount;
                    if(newCount === 0) {
                        // Have to put recipients as profiled
                        const profile = message?.recipients?.find(itm => itm.userId === message.senderId);
                        state.conversation[chatId][messageIdx].repliedProfiles = [profile];
                    }
                    state.conversation[chatId][messageIdx].repliesCount = newCount + 1 ;
                }
            }
        },
        setUnreadCount : (state,action) => {
            const {chatId} = action.payload;
            const chatIndex = state.chats.findIndex(c => c.chatId === chatId);
            if(chatIndex !== -1){
                const old = state.chats[chatIndex].unreadCount;
                if(old >= 1){
                    state.totalUnreadDmCount = state.totalUnreadDmCount - 1; 
                } 
                state.chats[chatIndex].unreadCount = 0;
            }
        },
        updateUnreadCount : (state,action) => {
            const {chatId,changeBy} = action.payload;
            const chatIndex = state.chats.findIndex(c => c.chatId === chatId);
            if(chatIndex !== -1){
                if(state.chats[chatIndex].unreadCount === 0){
                    const old = state.totalUnreadDmCount;
                    state.totalUnreadDmCount = old + 1;
                }
                else if(state.chats[chatIndex].unreadCount === 1 && changeBy < 0){
                    const old = state.totalUnreadDmCount;
                    state.totalUnreadDmCount = old - 1;
                }
                const currentCount = state.chats[chatIndex].unreadCount;
                state.chats[chatIndex].unreadCount = currentCount + changeBy;
            }            
        },
        reset: () => initialChatStates
    }
})
export const IChatAction = IChatSlice.actions;
export default IChatSlice;