import MDBox from "lib/components/MDBox";
import { Button,FormControl, FormControlLabel, FormGroup, Radio, RadioGroup, Switch, Typography } from "@mui/material";
import MDInput from "lib/components/MDInput";
import { useEffect, useRef, useState } from "react";
import useDebouncedWatch from "hooks/use-debounce-watch";
import { UserAPI } from "services/UserAPI";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import AppConstants from "constants/AppConstants";
import { checkIfDisaIsValid } from "util/UtilityFunctions";

const UserCellCallConfig = (
    {
        phoneId,
        userId,
        config, 
        onDisaUpdated = () => {}
    }) => {
    
    const firstRender = useRef(true);
    const token = useSelector(state => state.user.token);
    
    const [currConfig,setConfig] = useState(config);
    const [disaErr,setDisaErr] = useState(false);

    const [showSaveBtn,setShowSaveBtn] = useState(false);

    const updateSettings = () => {
        const payload = {
            api_triggered : Boolean(currConfig?.api_triggered) ? currConfig.api_triggered : '',
            disa_enabled : currConfig?.disa_enabled ? currConfig.disa_enabled : false,
            disa_pattern : Boolean(currConfig?.disa_pattern) ? currConfig.disa_pattern : '',
            enabled : currConfig.enabled
        }
        if(Boolean(currConfig.enabled)) {
            if(! JSON.parse(payload.disa_enabled) && payload.api_triggered?.trim().length === 0){
                toast.error("Api triggered field can not be empty.",{position: "bottom-right"});
                return;
            }
            if(JSON.parse(payload.disa_enabled) && payload.disa_pattern?.trim().length === 0) {
                toast.error("Disa pattern field can not be empty.",{position: "bottom-right"});
                // validate disa field....
                return;
            }
            if(JSON.parse(payload.disa_enabled) && !checkIfDisaIsValid(payload.disa_pattern)){
                setDisaErr(true);
                return;
            }
        }
        UserAPI.updateUserCellConfig({payload,phoneId,userId,token}).then(res => {
            toast.success("Updated successfully.");
            onDisaUpdated({phoneId,res: res.data,userId})
        }).catch(e => {
            toast.error(e?.response?.data?.message);
        })
    }

    useDebouncedWatch(() => {
        if(firstRender.current){
            firstRender.current = false;
            return;
        }
        setShowSaveBtn(true);
    },[currConfig,setShowSaveBtn]);

    const renderDisaConfig = (conf) => {
        if(conf?.disa_enabled){
            return  <>
            <MDInput
                key={"disa_input"}
                type="text"
                variant="standard"
                placeholder={AppConstants.DISA_SAMPLE_PATTERN}
                value={conf?.disa_pattern}
                onChange={(e) => {  
                    setConfig(prev => {
                        return{
                            ...prev,
                            disa_pattern : e.target.value
                        }
                    })}
                }
                fullWidth
            />
            {disaErr && <Typography color={"red"} mt={1} fontSize={12}>
                {AppConstants.DISA_ERR}
            </Typography>}            
            <MDBox display="flex" flexDirection="column" m={1}>
                <ul>
                    <li><Typography fontSize={"12px"} fontWeight={600}>{AppConstants.DISA_INS1}</Typography></li>
                    <li><Typography fontSize={"12px"} mt={1} fontWeight={600}>{AppConstants.DISA_INS2}</Typography></li>
                    <li><Typography fontSize={"12px"} mt={1} fontWeight={600}>{AppConstants.DISA_INS3}</Typography></li>
                </ul>
            </MDBox>
        </>
        }
        else{
            return <>
                <MDInput
                    key={"api_triggered_input"}
                    type="text"
                    label="Enter value here"
                    variant="standard"
                    value={conf?.api_triggered}
                    onChange={(e) => {  
                        setConfig(prev => {
                            return{
                                ...prev,
                                api_triggered : e.target.value
                            }
                        })                                            
                    }}
                    fullWidth
                />
            </>
        }
    } 

    useEffect(() => {
        if(config){
            setConfig(config);
        }
        else{
            setConfig({
                enabled : false,
                disa_enabled : false,
                api_triggered : '',
                disa_pattern : ''
            })
        }
    },[config]);

    return(
        <>
            <MDBox display="flex">
                <Switch checked={currConfig?.enabled} color="info" onChange={(e) => {
                    setConfig(prev => {
                        return {
                            ...prev,
                            enabled : e.target.checked
                        }
                    })
                }}/>
                <Typography>{AppConstants.ENABLE_CELL_CALL}</Typography>
            </MDBox>
            {Boolean(currConfig?.enabled) && 
                <MDBox key={"enabled"} display="flex" flexDirection="column" justifyContent="center" p={2} width="100%!important">
                    <FormGroup>
                        <FormControl>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-config-label"
                                name="row-radio-buttons-config"
                                onChange={(e) => {
                                    setConfig(prev => {
                                        return{
                                            ...prev,
                                            disa_enabled : e.target.value === 'true' ? true : false 
                                        }
                                    })
                                }}
                                value={currConfig?.disa_enabled?.toString()}
                            >
                                <FormControlLabel
                                    value={"true"}
                                    key={"disa"}
                                    control={<Radio />}
                                    label="DISA configuration"
                                />
                                <FormControlLabel
                                    value={"false"}
                                    key={"api"}
                                    control={<Radio />}
                                    label="API triggered call"
                                />
                            </RadioGroup>
                        </FormControl>
                    </FormGroup>
                    {renderDisaConfig(currConfig)}
                </MDBox>
            }            
            {showSaveBtn && <MDBox display="flex" justifyContent="flex-end">
                <Button
                    onClick={updateSettings}
                    variant="contained"
                    sx={{
                        borderRadius: "0px",
                        color: "white!important",
                        border: "unset",
                        marginLeft: "12px",
                        height: "32px",
                        minHeight: "unset",
                    }}                
                >{AppConstants.SAVE_BTN}</Button>
            </MDBox>}
        </> 
    )
}
export default UserCellCallConfig;