import React from "react";

import { Typography } from "@mui/material";

import Moment from "react-moment";

import { getDateConvertedFromUTC } from "util/UtilityFunctions";

const AssignmentDetails = ({ message, index, timezone }) => {
  return (
    <div
      id={"message_" + message?.id}
      key={message.id + index}
      style={{
        display: "flex",
        width: "100%",
        padding: "18px",
        justifyContent: "center",
      }}
    >
      <Typography fontWeight={"medium"} fontSize={"14px"}>
        {message.message} at (
        <Moment tz={timezone} format="LT">
          {getDateConvertedFromUTC(message.createdAt)}
        </Moment>
        ,{" "}
        <Moment tz={timezone} format="ll">
          {getDateConvertedFromUTC(message.createdAt)}
        </Moment>
        )
      </Typography>
    </div>
  );
};

export default AssignmentDetails;
