import DashboardLayout from "lib/layouts/LayoutContainers/DashboardLayout";
import AddButton from "ui/AddButton";
import { useEffect, useState } from "react";
import AppModal from "ui/AppModal";
import useHttp from "hooks/use-http";
import { useSelector } from "react-redux";
import DashboardNavbar from "lib/layouts/Navbars/DashboardNavbar";
import AddCompanyForm from "components/Company/AddCompanyForm";
import CompanyList from "components/Company/CompanyList";
import { getUserCompany } from "api/company";
import { ROLE } from "constants/AppConstants";

const Company = () => {
  const role = useSelector(state => state.user.role);
  const token = useSelector(state => state.user.token);
  const userId = useSelector(state => state.user.id);
  const {sendRequest : fetchCompanyList, data : companyList , status : fetchStatus} = useHttp(getUserCompany, true);
  const [addNewCompany, setAddNewCompany] = useState(false);
  const [companyEdited, setCompanyEdited]  = useState(false);

  const onCompanyAdded = ( res) => {
    setAddNewCompany(false);
    if(res){
      console.log(res)
      companyList.unshift(res);
    }
  }
    //// retrieve companies
    useEffect(() => {
      fetchCompanyList({token,userId});
    },[companyEdited,fetchCompanyList,token,userId])

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
       {role === ROLE.SUPER_ADMIN &&<AddButton
          onClick={() => {
            setAddNewCompany(true);
          }}
          text={"create new company"}
        />
        }   
        {fetchStatus === 'completed' && <CompanyList data={companyList} onCompanyEdited={()=> setCompanyEdited(true)}/>}
        {addNewCompany && (
          <AppModal
            rootStyle = {
              {
                "&.MuiModal-root":{
                  width:'40%',
                  left: '60%'
                }
              }
            }
            customStyle={{ 
                width: "100%",
                height: "100%",
            }}          
            onModalClose={() => {
              setAddNewCompany(false);
            }}
            heading={"New Company"}
          >
            <AddCompanyForm 
              closeModal={() => setAddNewCompany(false)} 
              token={token} 
              onCompanyAdded = {onCompanyAdded}
            />
          </AppModal>
        )}
      </DashboardLayout>
    </>
  );
};
export default Company;