import { carrierColums } from "constants/Columns";
import MDTypography from "lib/components/MDTypography";
import { useEffect, useState } from "react";
import Table from "ui/Table";
import { getProperDate } from "util/UtilityFunctions";
import CarrierAction from "./CarrierAction";
import { Delete } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import MDBox from "lib/components/MDBox";

const CarrierList = ({ data, onDelete }) => {
  const [carriers, setCarriers] = useState([]);
  const [rows, setRows] = useState([]);

  const onEdited = (editData) => {
    const updatedCarrier = carriers.map((c) => {
      if (c.id === editData.id) {
        return editData;
      } else return c;
    });
    setCarriers(updatedCarrier);
  };

  const formatRows = (res) => {
    const newRows = res?.map((c, i) => {
      const r = {
        sno: i + 1,
        name: c?.carrier_name,
        created_at: (
          <MDTypography
            component="a"
            href="#"
            variant="caption"
            color="text"
            fontWeight="medium"
          >
            {getProperDate(c.lastModifiedDate, "L")}
          </MDTypography>
        ),
        action: (
          <MDBox display="flex" alignItems="center">
            <CarrierAction carrier={c} onEdited={onEdited} />
            <Tooltip title="Delete Carrier">
              <IconButton
                aria-label="delete"
                onClick={() => onDelete(c.id)}
                sx={{ marginLeft: "8px" }}
              >
                <Delete />
              </IconButton>
            </Tooltip>
          </MDBox>
        ),
      };
      return r;
    });
    return newRows;
  };

  useEffect(() => {
    if (carriers) {
      const nr = formatRows(carriers);
      setRows(nr);
    }
  }, [carriers]);

  useEffect(() => {
    if (data) {
      setCarriers(data);
    }
  }, [data]);

  return (
    <>
      <Table heading={"Custom carriers"} columns={carrierColums} rows={rows} />
    </>
  );
};

export default CarrierList;
