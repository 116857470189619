const AppConstants = {
  HOME_PAGE_TITLE: "Welcome to the world of BLIQ!",
  HOME_PAGE_TEXT1: `Our creative team of innovators has been hard at work creating realworld solutions to simplify Business-to-Consumer communication.`,
  HOME_PAGE_TEXT2: `We have been listening – intently – to our customers’ needs and have created an exclusive suite of features that will take messaging to a whole new realm.`,
  HOME_PAGE_TEXT3: `BLIQ will allow your entire team to utilize  messaging capabilities – with text and  WhatsApp integrated into the same interface – `,
  HOME_PAGE_TEXT31: `using your company phone number`,
  HOME_PAGE_TEXT32: `, to the larger success of your business marketing and communication needs.`,
  HOME_PAGE_TEXT4: `Give BLIQ a shot, and you will soon be wondering how you ever managed without it!`,
  GOOGLE_CONTACTS_SCOPE: "https://www.googleapis.com/auth/contacts",
  REQUEST_FAIL: "Your request can not be fulfilled. Please try later.",
  TWO_DIGIT_REGEX: /^\d{1}|[1-9]\d{1}$/,
  UPDATED_SUCCESS: "Updated successfully.",
  SOMETHING_WENT_WRONG: "Something went wrong. Please try later.",
  VALID_CREDIT_AMOUNT_WARNING:
    "Please enter a valid amount. Upto 1000 credit is allowed.",
  CREDIT_AMOUNT_THRESHHOLD: 10000000,
  UNSUPPORTED_MEDIA_MSG: "Unsupported media format. Unable to preview.",
  ADD_GROUP_RECIPIENTS_TEMPLATE_URL:
    "https://app--media.s3.amazonaws.com/system_default_sounds/add_recipients_group_template.xlsx",
  FUNDRAISER_BULK_TEMPLATE:
    "https://app--media.s3.amazonaws.com/system_default_sounds/fundraisers_bulk_template.xlsx",
  BULK_USER_TEMPLATE:
    "https://app--media.s3.amazonaws.com/system_default_sounds/bulk_users.xlsx",
  ACTION_NOT_ALLOWED: "You are not allow to perform this action",
  LOGO_PURPOSE_TEXT:
    "Logo will be used in Crowdfunding donation page and receipts.",
  HARD_REFRESH_KEY: "hard_refreshed",
  DLC_COMPLIANCE_MESSAGE:
    "For 10DLC regulations compliance, you must specify the group’s category.",
  START_CHAT: "Start a chat now...",
  ADD_MULTIPLE_NUMBER_WITH_COMMA_SEPATED:
    "You can enter multiple numbers at once by separating them with a comma.",
  UPDATE_SUCCESSFUL: "Updated successfully.",
  MESSAGE_DELAY: "Amount of seconds to delay outbound messages by",
  DELAY_EXPLAIN:
    "During this time, you'll be able to retract and edit your outbound messages.",
  CARRIER_DEDAULT_MSG_INFO: "Default messages allowed per minute.",
  // WELCOME_BLIQ : "Welcome to BLIQ!",
  BROADCAST_NO_MESSAGE: "You have no existing conversations in this broadcast.",
  BROWSER_NOTIFICATION_PERMISSION:
    "https://app--media.s3.amazonaws.com/system_default_sounds/allow_browser_notification_permission.png",
  RECIPIENT_ASSIGNED_SOUND:
    "https://app--media.s3.amazonaws.com/system_default_sounds/assigned_unassigned.mp3",
  RECIPIENT_UNASSIGNED:
    "https://app--media.s3.amazonaws.com/system_default_sounds/assigned_unassigned.mp3",
  SHEET_PERMISSION_ERR:
    "We're having trouble retrieving the messages from your Google Sheet, please make sure that access is allowed for anyone with the link.",
  BROADCAST_INSTRUCTION:
    "File should contain contact numbers and the corresponding messages. If you’d like to send the same message to the entire group, first create a group. From there you’ll be able to add and/or upload contact numbers.",
  IMPORT_STATUS_FIELD_MAP_COLUMN:
    "Please select which value within this column indicates the message status. The selected value will be marked as Successful. All other values will be marked as Failed.",
  URL_REGEX:
    /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&//=]*)/g,
  WA_TEMPLATE_SYNCED: "Whatsapp template synced successfully.",
  GOOGLE_SHEET_URL_PLACEHOLDER: "Paste the Google Sheets URL here.",
  MOBO_APP_CELL_CALL: "Mobile app phone calls",
  DISA_INS1:
    "Please indicate your DISA dial pattern. Enter the access phone number for your phone system along with the access PIN number (if any).",
  DISA_INS2:
    "If you require a Pause while dialing, insert the letter P in the dial pattern.",
  DISA_INS3:
    "For a long pause, enter PP.\nYou also must indicate where in the dial pattern we should enter the destination phone number by inserting the following placeholder {DESTINATION_NUMBER}.",
  ENABLE_CELL_CALL: "Enable cellular calls",
  ENABLE_VOIP_CALL: "Enable voip calling",
  SAVE_BTN: "Save",
  DISA_BEFORE_SAVED_WARN:
    "Updating this setting will effect all users' abilities to place cellular calls from this company number. Would you like to proceed?",
  DISA_SAMPLE_PATTERN: "Sample pattern: 2121111111PP1234P{DESTINATION_NUMBER}#",
  DISA_DESTINATION_PLACEHOLDER: "{DESTINATION_NUMBER}",
  CHOOSE_EXCEL: "Choose excel file",
  DISA_ERR:
    "You must indicate where in the dial pattern we should enter the destination phone number by inserting the following placeholder {DESTINATION_NUMBER}. Sample pattern: 2121111111PP1234P{DESTINATION_NUMBER}#",
  CARRIER_INFO:
    "You can connect an external carrier or messaging service using our external API connector.",
  EDIT_CARRIER_INFO:
    "You can edit an external carrier or messaging service added using our external API connector.",
  WA_TEMPLATE_NAME_INS:
    "Only alphabets are allowed. Space will be replaced with an underscore.",
  API_SETTING_TEXT:
    "Allow API triggered messages to bring the conversation to the Recent Conversations panel.",
  RECIPIENT_OPT_OUT:
    "This recipient has opted out of further communications. You can't send messages to this recipient.",
  RECIPIENT_ADD_SUCCESS: "Recipients have been added to the group successfully."  
};
export default AppConstants;

export const TAG_STATE = {
  INITIAL: "initial",
  CREATING: "creating",
  END: "end",
  FINISH: "finish",
};
export const NOTIFICATION_MEDIUM = {
  NONE: "NONE",
  SMS: "SMS",
  EMAIL: "EMAIL",
  ALL: "ALL",
};

export const WHATSAPP_INS = {
  TIP: "You can include dynamic place-holders using {{ }} in the template, that you'll later personalize while messaging the customer. The following is an example where {{1}}, {{2}} and {{3}} are the dynamic place-holders as per the need.",
  TIP_EXAMPLE:
    "Hello {{1}}! We successfully processed your payment for invoice {{2}} using the credit card on file ending in {{3}}. Thank you!",
};

export const AUTO_REPLY = {
  AUTO_REPLY_INACTIVE_INS:
    "Auto-replies are not sent during active conversations. After how many minutes of inactivity should a conversation be considered dormant?",
};

export const ROLE = {
  SUPER_ADMIN: "SUPER_ADMIN",
  ADMIN: "ADMIN",
  USER: "USER",
};

export const ERRORS = {
  TELE_FILE_SIZE_ERR: "Media upto 5MB is allowed.",
};

export const ACTIONS = {
  EDIT: "edit",
  DELETE: "delete",
  NONE: "none",
};
export const OperationStatus = {
  success: "success",
  failed: "failed",
};

export const WARNINGS = {
  UNASSIGN_COMPNAY_PHONE_WARN: "Are you sure want to unassign?",
  REMOVE_USER_WARN: "Are you sure want to remove this user from the company?",
  REMOVE_USER_WARN_DESC:
    "Removing a user will delete the user from the company. But don't worry all chat history will be preserved.",
  PHONE_UNASSIGNED_FROM_COMPANY_WARN_DESC:
    "All chat related to this number will be deleted. Make sure to export the chat before deleting.",
};

export const CARRIER_INS = {
  NO_AUTH: "No credentials are passed during API call.",
  WEBHOOK_INS:
    "Please save the above webhook in your carrier's settings. Once you post to this webhook, you can view a sample response below and advise which keys correspond to which values.",
};

export const MAPPED_WITH = {
  AUTO_REPLY: "auto_reply",
  BROADCAST_GROUP: "broadcast_group",
  PAYMENT_ASSOCIATED: "payment_associated",
};

export const CARRIER_REQ_PARAMS = [
  { key: "", value: "sender" },
  { key: "", value: "company number" },
  { key: "", value: "message" },
  { key: "", value: "media urls" },
];

export const CARRIER_REQ_PARAMS_OPT = [
  { key: "destination", value: "destination" },
  { key: "company_number", value: "company number" },
  { key: "message", value: "message" },
  { key: "media", value: "media urls" },
];

export const INBOUND_CARRIER_REQ_PARAMS_OPT = [
  { key: "destination", value: "destination" },
  { key: "company_number", value: "company number" },
  { key: "message", value: "message" },
  { key: "media", value: "media urls" },
  { key: "id", value: "id" },
];
