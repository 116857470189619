import axios from "core/axios";
import { BASE_URL } from "constants/Endpoints";

export const KeywordSettingsAPI = {

    getKeywords: async({token,companyId}) => {
        return await axios.get(`${BASE_URL}/settings/keywords/${companyId}`,{
            headers : {
                'Authorization': 'Bearer ' + token
            }
        });
    },

    addKeywords : async({token,payload}) => {
        return await axios.post(`${BASE_URL}/settings/keywords`,JSON.stringify(payload),{
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
            }
        })
    },
    removeKeywords : async({token,keywordId}) => {
        return await axios.delete(`${BASE_URL}/settings/keywords/${keywordId}`,{
            headers : {
                'Authorization': 'Bearer ' + token,
            }
        });
    } 
}