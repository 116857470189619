import { BASE_URL } from "constants/Endpoints";
import { uploadCsvToPool } from "api/numberpool";
import ShowCarrier from "components/Carrier/ShowCarrier";
import ChooseFile from "components/CommonUI/ChooseFile";
import useHttp from "hooks/use-http";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import AppModal from "ui/AppModal";
import AppConstants from "constants/AppConstants";

let carrier_id = undefined;

const UploadBulkNumber = ({onUploaded, onCancel}) => {

    const token = useSelector(state => state.user.token);

    const {sendRequest : uploadCsvFn , status, error, data} = useHttp(uploadCsvToPool,false);
    
    const onCarrierSelected = (res) => {
        carrier_id = res;
    }
    
    const onFileSelected = (file) => {
        if(file){
            const formData = new FormData();
                formData.append('file',file);
                formData.append("carrier_id",carrier_id)
                if(carrier_id){
                    uploadCsvFn({formData,token});            
                }
                else{
                    toast.error("Please select a carrier.")
                }
                // onCsvAdded(file);
        }
    }

    useEffect(() => {
        if(status === 'completed'){
            if(data){
                onUploaded(data);
            }
            else{
                toast.error(error);
            }
        }
    },[status,error,data])

    return (
        <AppModal
            onModalClose={onCancel}
            heading={AppConstants.CHOOSE_EXCEL}
        >
            <ShowCarrier onCarrierSelected={onCarrierSelected}/>
            <ChooseFile onFileSelected={onFileSelected} templateTooltipMsg="Download template" showTemplate={true} templateUrl= {BASE_URL + "/download/template/bulk_numbers.xlsx"} />
        </AppModal>
    )
}
export default UploadBulkNumber;