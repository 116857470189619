import { Info } from "@mui/icons-material";
import { Button, Popover, TextField, Tooltip, Typography } from "@mui/material";
import ChooseFileGeneric from "components/CommonUI/ChooseFileGeneric";
import ConsentOptions from "components/GroupChat/ConsentOptions";
import SheetInsUI from "components/util/SheetInsUI";
import AppConstants from "constants/AppConstants";
import usePopup from "hooks/use-popup";
import MDBox from "lib/components/MDBox";
import { memo, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { TeleGroupServiceAPI } from "services/Chat/TeleGroupServiceAPI";
import { loaderAction } from "store/loader-slice";
import AppModal from "ui/AppModal";
import { isPromotionalGroup } from "util/GroupUtil";

const UPLOAD_INITIAL_STATE = {file : undefined,url : undefined}
const UploadGroupRecipients = ({group,currentActivePhone,onRecipientsUploaded,OnConsentOption}) => {

    const { popoverId, anchorEl, openPopover, handleOpenPopup, handleClosePopup } = usePopup();

    const dispatch = useDispatch();
    
    const currentUser = useSelector(state => state.user);
    const [consentRequired, setConsentRequired] = useState(undefined);
    const [uploadData,setUploadData] = useState(UPLOAD_INITIAL_STATE);

    const onFileAdded = (file) => {
        setUploadData({file : file,url : undefined});
    }

    const onUrlInput = (e) => {
        const url = e.target.value;
        setUploadData({file : undefined, url : url});
    }

    const onSubmitHandler = useCallback(() => {
        if(uploadData.file){
            groupCsvUpload(uploadData.file);
            setUploadData(UPLOAD_INITIAL_STATE);
        }
        else if(uploadData.url){
            onUrlSubmit(uploadData.url);
            setUploadData(UPLOAD_INITIAL_STATE);
        }
    },[uploadData,currentActivePhone,group,currentUser])

    const groupCsvUpload = (file) => {
        const data = new FormData();
        data.append("file",file);
        data.append("groupId",group?.groupId);
        data.append("phone",currentActivePhone.phone)
        data.append("phoneId",currentActivePhone?.phoneId)
        if(isPromotionalGroup(group)){
            TeleGroupServiceAPI.getConsentStatusForFile({formData : data,token : currentUser.token}).then(res => {
                const requiredConsent = res.data.filter(itm => !itm.consent_given).map(itm => itm.recipient);
                const consentGiven = res.data.filter(itm => itm.consent_given);
                if(requiredConsent.length > 0){
                  setConsentRequired(requiredConsent);
                }
                // And for others proceed with addition...
                if(consentGiven > 0){
                  //Format consent given to create payload;
                  const addConsentGiven = consentGiven.map(c => {
                    return{
                      ...c,
                      groupId : group?.groupId,
                      phoneId : currentActivePhone.phoneId
                    }
                  })
                  onRecipientsUploaded(addConsentGiven);
                }
            }).catch(e => console.log(e));
        }
        else{
            dispatch(loaderAction.show());
            TeleGroupServiceAPI.uploadGroupRecipients({formData : data,token : currentUser.token}).then(res => {
                toast.success(`Recipients have been added to the group successfully.`);
                onRecipientsUploaded(res.data);
                dispatch(loaderAction.hide());
            }).catch(e => {
                toast.error(e?.response?.data?.message)
                console.log(e);
                dispatch(loaderAction.hide());
            });
        }
    }

    const onUrlSubmit = (url) => {
        const payload = {
            groupId: group?.groupId,
            phone: currentActivePhone.phone,
            phoneId: currentActivePhone?.phoneId,
            url
        }
        if(isPromotionalGroup(group)){
            dispatch(loaderAction.show());
            TeleGroupServiceAPI.getConsentStatusForUrl({payload,token : currentUser.token}).then(res => {
                const requiredConsent = res.data.filter(itm => !itm.consent_given).map(itm => itm.recipient);
                const consentGiven = res.data.filter(itm => itm.consent_given);
                if(requiredConsent.length > 0){
                  setConsentRequired(requiredConsent);
                }
                // And for others proceed with addition...
                if(consentGiven > 0){
                  //Format consent given to create payload;
                  const addConsentGiven = consentGiven.map(c => {
                    return{
                      ...c,
                      groupId : group?.groupId,
                      phoneId : currentActivePhone.phoneId
                    }
                  })
                  onRecipientsUploaded(addConsentGiven);
                }
                dispatch(loaderAction.hide());
            }).catch(e => {
                console.log(e);
                dispatch(loaderAction.hide());
            });
        }
        else{
            dispatch(loaderAction.show());
            TeleGroupServiceAPI.uploadSheetRecipients({payload,token : currentUser.token}).then(res => {
                toast.success(AppConstants.RECIPIENT_ADD_SUCCESS);
                onRecipientsUploaded(res.data);
                dispatch(loaderAction.hide());
            }).catch(e => {
                handleOpenPopup();
                dispatch(loaderAction.hide());
            });
        }

    }

    return(
        <>
            <MDBox display="flex" ml={2} flexDirection="row">
                <ChooseFileGeneric onFileSelected={onFileAdded} showTemplate={true} templateUrl={AppConstants.ADD_GROUP_RECIPIENTS_TEMPLATE_URL}/>
                <Typography alignItems={"center"} alignContent={"center"} margin={"4px"}>{"OR"}</Typography>
                <MDBox display="flex" justifyContent="space-between" m={2}>
                    <TextField onChange={onUrlInput} fullWidth variant="standard" type="text" placeholder={AppConstants.GOOGLE_SHEET_URL_PLACEHOLDER}/>
                    <MDBox display="flex" width="40px" height='40px'>
                    <Tooltip title="Make sure the access is allowed.">
                        <Info sx={{cursor:'pointer'}}/>
                    </Tooltip> 
                    </MDBox>
                </MDBox>    
                <MDBox display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                    {(uploadData.file || uploadData.url) && <Button
                        onClick={onSubmitHandler}
                        size={"medium"}
                        variant="contained"
                        sx={{borderRadius: "0px",color: "white!important",maxWidth:'200px!important'}}
                    >
                        Submit 
                    </Button>}
                </MDBox>                        
            </MDBox>
            {Boolean(consentRequired) && 
            <AppModal
                closeOnClick={false}
                onModalClose={() => setConsentRequired(undefined)}
                heading={"Consent options"}
            >
                <ConsentOptions OnConsentOption={OnConsentOption}/>
            </AppModal>
            }  
            <Popover
                id={popoverId}
                open={openPopover}
                anchorEl={anchorEl}
                onClose={handleClosePopup}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "center" }}
                sx={{
                    "& .MuiPopover-paper": {
                      backgroundColor: "white!important",
                      display: "flex",
                      justifyContent: "center",
                      border: '1px solid rgb(221, 221, 221) rgb(221, 221, 221) rgb(245, 245, 245)'
                    },
                }}                
            >
               <SheetInsUI/>
            </Popover>                       
        </>
    )
}
export default memo(UploadGroupRecipients);