import { Delete } from "@mui/icons-material";
import MDBox from "lib/components/MDBox";
import MDInput from "lib/components/MDInput";
import { Tooltip, IconButton } from "@mui/material";
import { useState, useEffect } from "react";
import RequiredParamsDrop from "components/Carrier/RequiredParamsDrop";

const KeyValue = ({
  item,
  onDelete,
  onChange,
  showDelete = true,
  canEdit = true,
  required = false,
  availableOptions = [],
  showValueField = true,
  isDeletable = false,
  selectedImageType,
  setSelectedImageType,
  onmediaTypeChange,
  mediaModalClose,
}) => {
  const [value, setValue] = useState(item);

  useEffect(() => {
    // Update local state when the incoming item prop changes
    if (item !== value) {
      setValue(item);
    }
  }, [item]);

  const handleImageTypeChange = (newImageType) => {
    setSelectedImageType(newImageType);
    onmediaTypeChange(newImageType);
  };

  const handleKeyChange = (e) => {
    const newKey = e.target.value;
    if (newKey !== value.key) {
      setValue((prev) => ({
        ...prev,
        key: newKey,
      }));
    }
  };

  const handleValueChange = (e) => {
    const newValue = e.target.value;
    if (newValue !== value.value) {
      setValue((prev) => ({
        ...prev,
        value: newValue,
      }));
    }
  };

  const handleParamChange = (selectedParam) => {
    setValue((prev) => ({
      ...prev,
      param: selectedParam,
    }));
  };

  useEffect(() => {
    onChange(value); // Notify parent with the updated value
  }, [value, onChange]); // Ensure onChange only runs when value changes

  const handleDeleteClick = () => {
    onDelete(); // Call delete handler
  };

  return (
    <MDBox
      display="flex"
      width="85%"
      flexDirection="row"
      mb={2}
      alignItems="center"
    >
      {/* Key Field */}
      <MDBox flex={2} padding="4px">
        <MDInput
          fullWidth
          required={required}
          type="text"
          label={"Key"}
          value={value.key}
          onChange={handleKeyChange}
        />
      </MDBox>

      {/* Param (Dropdown) */}
      <MDBox flex={1}>
        <RequiredParamsDrop
          value={value.param || ""}
          onChange={handleParamChange}
          onImageTypeChange={handleImageTypeChange}
          imageType={selectedImageType}
          options={availableOptions}
          style={{ height: "28px" }}
          label={"Key Map"}
          mediaModalClose={mediaModalClose}
        />
      </MDBox>

      {/* Value Field */}
      {showValueField && (
        <MDBox flex={2} padding="4px">
          <MDInput
            fullWidth
            type="text"
            label={"Value"}
            value={value.value}
            onChange={canEdit ? handleValueChange : () => {}}
          />
        </MDBox>
      )}

      {/* Delete Button */}
      {showDelete && (
        <MDBox
          padding="4px"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Tooltip title="Delete this row" arrow>
            <IconButton
              disabled={!isDeletable} // Disabled when not deletable
              onClick={handleDeleteClick}
              sx={{
                border: !isDeletable ? "2px solid gray" : "2px solid #1976d2",
                borderRadius: "8px",
                padding: "4px",
                "&:hover": {
                  backgroundColor: "rgba(25, 118, 210, 0.08)",
                },
              }}
            >
              <Delete
                sx={{
                  cursor: !isDeletable ? "not-allowed" : "pointer",
                  color: !isDeletable ? "gray" : "#1976d2",
                }}
              />
            </IconButton>
          </Tooltip>
        </MDBox>
      )}
    </MDBox>
  );
};

export default KeyValue;
