import { memo, useEffect } from "react";
import { useState } from "react";
import Moment from "react-moment";
import { useSelector } from "react-redux";
import { CustomerAPI } from "services/CustomerAPI";
import Table from "ui/Table";
import CustomerAction from "./CustomerAction";

const columns = [
    { Header: "sno", accessor: "sno", align: "center" },
    { Header: "company Name", accessor: "company", align: "center" },
    { Header: "Plan Type", accessor: "plan", align: "center" },
    { Header: "status", accessor: "status", align: "center" },
    { Header: "Submitted At", accessor: "created_at", align: "center" },
    { Header: "Action", accessor: "action", align: "center" },
  
];
const CustomerList = ({}) => {

    const token = useSelector(state => state.user.token);
    const [rows,setRows] = useState([]);

    useEffect(() => {
        CustomerAPI.getAllCustomers(token).then(res => {
            const newRows = res.data?.map((item,index) => {
                return{
                    sno : index + 1,
                    company : item?.profileData.companyName,
                    plan : item?.profileData.plan,
                    status : item.status,
                    created_at : <><Moment format="L">{item?.createdDate ? item?.createdDate : item?.lastModifiedDate}</Moment>&nbsp;<Moment format="LT">{item?.createdDate ? item?.createdDate : item?.lastModifiedDate}</Moment></>,
                    action :<CustomerAction customer={item}/>
                }
            });
            setRows(newRows);
        }).catch(e => console.log(e));
    },[token]);

    return(
        <Table heading={"Customers"} columns={columns} rows={rows}/>
    )
}

export default memo(CustomerList);