import { MessageWrapper } from "pages/Chat/ChatStyles";
import { MessageChat } from "pages/Chat/ChatStyles";
import { MainChatPanel } from "pages/Chat/ChatStyles";
import { useDispatch, useSelector } from "react-redux";
import NoContent from "../NoContent";
import { useCallback, useEffect } from "react";
import MDBox from "lib/components/MDBox";
import { CircularProgress, SpeedDial, Typography } from "@mui/material";
import Moment from "react-moment";
import SingleRightMessage from "../SingleRightMessage";
import { MESSAGE_EVENTS } from "constants/ChatConstants";
import SingleLeftMessage from "../SingleLeftMessage";
import AssignmentDetails from "../AssignmentDetails";
import { VIEWS } from "constants/ChatConstants";
import { useState } from "react";
import useScrollToBottom from "hooks/use-scroll-to-bottom";
import useChat from "hooks/use-chat";
import { memo } from "react";
import { chatVariablesAction } from "store/chat-variables-slice";
import { KeyboardArrowDown } from "@mui/icons-material";
import InfiniteScroll from "react-infinite-scroll-component";
import { chatAction } from "store/chat-slice";
import { getDateConvertedFromUTC } from "util/UtilityFunctions";
import { messageAction } from "store/message-slice";

const NConversation = ({ setMessageState }) => {
  useChat();

  const dispatch = useDispatch();
  const currentActiveRecipient = useSelector(
    (state) => state.chat.current_active_recipient
  );
  const view = useSelector((state) => state.chat.current_active_view);
  const scrollChatBottomBtn = useSelector(
    (state) => state.chatVariables.scrollChatBottomBtn
  );
  const conversation = useSelector(
    (state) => state.message.conversation[currentActiveRecipient.id]
  );
  const messages = conversation?.messages;
  console.log("Messages List", messages);
  const currentMessageView = useSelector(
    (state) => state.chat.current_message_view
  );
  const timezone = useSelector(
    (state) => state.userDetail?.details?.userSettings?.timeZone
  );
  const activeCheckbox = useSelector(
    (state) => state.chatVariables.activeCheckbox
  );
  const isCheckboxSelected = useSelector(
    (state) => state.chatVariables.isCheckboxSelected
  );
  const [inlineSearch, setInlineSearch] = useState({
    search: false,
    pattern: "",
    criterion: "NORMAL_SEARCH",
  });
  const [lastReadMessage, setLastReadMessage] = useState();
  const [scroll, setScroll] = useState(true);
  const { scrollRef, scrollToBottom } = useScrollToBottom(
    messages,
    lastReadMessage,
    scroll
  );

  const loadMore = useCallback(() => {
    console.log("loading more conversation....");
    if (scroll) {
      setScroll(false);
    }
    dispatch(
      messageAction.updateConversationCurrentPage({
        recipientId: currentActiveRecipient.id,
      })
    );
  }, [scroll, dispatch, currentActiveRecipient]);

  const onEventPerFormInMessageComponent = useCallback(
    (action, data) => {
      if (action === "CHECKBOX") {
        if (!data.checked) {
          dispatch(
            chatVariablesAction.pushId({
              key: currentActiveRecipient?.id,
              id: data.id,
            })
          );
        } else {
          const newMap = activeCheckbox[
            currentActiveRecipient?.id
          ].messageIds.filter((item) => item !== data.id);
          // activeCheckbox.get(currentActiveRecipient?.id).messageIds = newMap;
          dispatch(
            chatVariablesAction.updateIds({
              ids: newMap,
              key: currentActiveRecipient.id,
            })
          );
        }
      }
    },
    [currentActiveRecipient, activeCheckbox]
  );

  const formatMessages = useCallback(
    (message, index) => {
      console.log("Messages List True", message);
      const showReadBy = index === 0 && messages[index]?.inbound;
      if (
        message?.messageEvent === MESSAGE_EVENTS.CONVERSATION_ASSIGNED ||
        message?.messageEvent === MESSAGE_EVENTS.CONVERSATION_UNASSIGNED
      ) {
        return (
          <AssignmentDetails
            message={message}
            index={index}
            timezone={timezone}
          />
        );
      } else {
        if (view === VIEWS.SCHEDULED_VIEW) {
          if (message?.inbound === false && message?.status === "scheduled") {
            return (
              <SingleRightMessage
                user={message?.sendByUser}
                setMessageState={setMessageState}
                check={activeCheckbox[currentActiveRecipient?.id]?.selected}
                onEventPerform={onEventPerFormInMessageComponent}
                key={"right_msg" + index}
                message={message}
                searchActive={inlineSearch}
                checkboxSelected={isCheckboxSelected}
              />
            );
          } else return <></>;
        } else {
          if (message?.inbound === true) {
            return (
              <SingleLeftMessage
                user={message?.sendByUser}
                check={activeCheckbox[currentActiveRecipient?.id]?.selected}
                onEventPerform={onEventPerFormInMessageComponent}
                key={"left_msg" + message.id + index}
                message={message}
                showReadBy={showReadBy}
                searchActive={inlineSearch}
                checkboxSelected={isCheckboxSelected}
              />
            );
          } else {
            return (
              <SingleRightMessage
                user={message?.sendByUser}
                setMessageState={setMessageState}
                check={activeCheckbox[currentActiveRecipient?.id]?.selected}
                key={"right_msg" + index}
                message={message}
                searchActive={inlineSearch}
                onEventPerform={onEventPerFormInMessageComponent}
                checkboxSelected={isCheckboxSelected}
              />
            );
          }
        }
      }
    },
    [
      messages,
      view,
      currentActiveRecipient,
      isCheckboxSelected,
      activeCheckbox,
      setMessageState,
      timezone,
    ]
  );

  useEffect(() => {
    if (currentActiveRecipient && messages) {
      //TODO:: last read logic to implement, considering when we are loading more data on inverse scroll
      const lastRead = messages.find((itm) => !itm.messageRead && itm.inbound);
      if (lastRead) {
        setLastReadMessage(lastRead.id);
      }
    }
  }, [currentActiveRecipient, messages]);

  useEffect(() => {
    const _lastMsgType = messages?.[0]?.type || "SMS";
    dispatch(chatAction.setCurrentMessageType(_lastMsgType));
  }, [messages]);

  return (
    <>
      <MainChatPanel key={"main_message_wrapper"}>
        <MessageWrapper key={"message_wrapper"}>
          <MessageChat
            ref={scrollRef}
            id="scrollableDiv_normal"
            key={"message_chat"}
            style={{
              height: 300,
              overflowY: "auto",
              overflowX: "hidden",
              display: "flex",
              flexDirection: "column-reverse",
              // overflow: auto;
            }}
          >
            {messages?.length > 0 ? (
              <InfiniteScroll
                dataLength={messages?.length}
                inverse={true}
                next={loadMore}
                style={{ display: "flex", flexDirection: "column-reverse" }}
                hasMore={
                  conversation?.currentPage < conversation?.totalPages &&
                  conversation?.totalPages > 1
                }
                loader={
                  <MDBox
                    width="100%"
                    sx={{ overflow: "hidden" }}
                    display="flex"
                    justifyContent="center"
                  >
                    <CircularProgress />
                  </MDBox>
                }
                scrollableTarget="scrollableDiv_normal"
              >
                {messages
                  ?.filter((itm) => {
                    if (currentMessageView === "SMS") {
                      return itm.type === "SMS";
                    } else if (currentMessageView === "WHATSAPP") {
                      return itm.type === "WHATSAPP";
                    } else return true;
                  })
                  ?.map((msg, index) => formatMessages(msg, index))}
              </InfiniteScroll>
            ) : (
              <NoContent
                heading={"h5"}
                style={{ height: "100%" }}
                message={"You have no existing conversations with this number."}
              />
            )}
          </MessageChat>
        </MessageWrapper>
      </MainChatPanel>
      {scrollChatBottomBtn === true && (
        <SpeedDial
          onClick={() => scrollToBottom()}
          ariaLabel="scroll to bottom"
          sx={{ position: "absolute", bottom: 38, right: 16 }}
          icon={
            <KeyboardArrowDown
              sx={{ height: "24px!important", width: "24px!important" }}
            />
          }
        ></SpeedDial>
      )}
    </>
  );
};
export default memo(NConversation);
