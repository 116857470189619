export const validateProfileInfo = (profileInfo,profileValidation) => {
    let validationFailed = false;
    console.log({profileInfo})
    if (profileInfo?.firstName?.trim().length === 0 ){
        profileValidation = {...profileValidation,firstName : true}
        console.log("Named failed");
        validationFailed = true;
    }
    if (profileInfo?.lastName?.trim().length === 0 ){
        profileValidation = {...profileValidation,lastName : true}
        console.log("Last name failed");
        validationFailed = true;
    }
    if (profileInfo?.companyName?.trim().length === 0 ){
        profileValidation = {...profileValidation,companyName : true}
        console.log("Company failed");        
        validationFailed = true;
    }
    if (profileInfo?.contactNumber.trim().length === 0 || !(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/).test(profileInfo?.contactNumber)){
        profileValidation = {...profileValidation,contactNumber : true};
        console.log("Contact number failed");
        validationFailed = true;
    }
    if (profileInfo?.email.trim().length === 0){
        profileValidation = {...profileValidation,email : true}
        console.log("email failed");
        validationFailed = true;
    }
    if (profileInfo?.creditCardNumber?.trim().length === 0 || profileInfo?.creditCardNumber?.trim().length < 15 || profileInfo?.creditCardNumber?.trim().length > 16){
        profileValidation = {...profileValidation,creditCardNumber : false};
    }
    if(profileInfo?.expiration?.trim().length === 0 || profileInfo?.expiration?.trim().length > 4){
        profileValidation = {...profileValidation,expiration : false};
    }
    if(profileInfo?.address?.trim().length === 0){
        profileValidation = {...profileValidation,address : true};
        console.log("address failed");

        validationFailed = true;
    }
    if(profileInfo?.city?.trim().length === 0){
        profileValidation = {...profileValidation,city : true};
        validationFailed = true;
    }
    if(profileInfo?.state?.trim().length === 0){
        profileValidation = {...profileValidation,state : true};
        validationFailed = true;
    }
    if(profileInfo?.zipcode?.trim().length === 0){
        profileValidation = {...profileValidation,zipcode : true};
        validationFailed = true;
    }
    return {
        newValidation : profileValidation,validationFailed
    }
}
