export const DonationFormValidator =  {
    validExpiry : (expiry) => {
        let value = expiry.replace(/\D/g, '');
        let first2Digit = value.slice(0, 2);
        if(parseInt(first2Digit) > 12){
            return false;
        }
        return value.length === 4;    
    },
    validCardNumber : (cardNumber) => {
        let firstDigit = cardNumber.slice(0,1);
        firstDigit = parseInt(firstDigit);
        if(firstDigit === 3) {
            return cardNumber?.length === 15;
        }
        else if(firstDigit >= 4 && firstDigit <= 6){
            return cardNumber?.length === 16;
        }
        return false;
    },
    validCvv : (xCVV,xCardNum) => {
        return xCVV?.length > 0 && (xCardNum?.substring(0,1) === '3' ? xCVV?.length === 4 : xCVV?.length === 3)
    },
    maxCvvInput : (xCardNum) => {
        return xCardNum?.substring(0,1) === '3' ? 4 : 3; 
    }
}