import ILeftChatNavBar from "components/IChat/ILeftChatNavBar";
import { ChatLeftPanel, ChatMainWrapper, ChatRightPanel, LeftChatPanel, } from "./ChatStyles";
import RecipientsList from "components/IChat/NChat/RecipientsList";
import NoRecipientSelected from "components/util/NoRecipientSelected";
import MiddleNavBar from "components/IChat/NChat/MiddleNavBar";
import IConversation from "components/IChat/IConversation";
import { useDispatch, useSelector } from "react-redux";
import MDBox from "lib/components/MDBox";
import { useAutoAnimate } from '@formkit/auto-animate/react'
import { VIEWS } from "constants/ChatConstants";
import IGroupConversation from "components/IChat/GChat/IGroupConversation";
import GMiddleNavBar from "components/IChat/GChat/GMiddleNavBar";
import { memo, useCallback, useEffect } from "react";
import IChatFooterWrapper from "components/IChat/NChat/IChatFooterWrapper";
import IGroupChatFooterWrapper from "components/IChat/GChat/IGroupChatFooterWrapper";
import ChatSideBar from "components/CommonUI/ChatSideBar";
import { useState } from "react";
import MediaQuery from "react-responsive";
import { draftsMessageAction } from "store/draft-message-slice";
import { getAsDataUrl } from "util/MediaUtil";
import useIchat from "hooks/IChat/use-ichat";
import { IVIEWS } from "constants/ChatConstants";

const steps = [
    {
        selector: '#profile__icon',
        content: 'Click on profile icon to explore more options',
    },
    {
        selector : '#tele__side__bar',
        content : 'Side bar'
    },
    {
        selector : '#ic__side__bar',
        content : 'Internal chat Side bar'
    }
]

const IChat = ({showAlert}) => {
    
    const dispatch = useDispatch();
    const showTour = useSelector(state => state.ichatVariables.showTour);
    const[currentShowTour,setShowTour] = useState(false);
    const [messageState, setMessageState] = useState({
        message: "",
        attachement: undefined,
      });
    const [draftUserInfo , setDraftsUserInfo] = useState({m:"",id:null})

    const currentActiveChat = useSelector(state => state.Ichat.currentActiveChat);
    const currentActiveGroup = useSelector(state => state.Igroup.currentActiveGroup);
    const currentActiveView =  useSelector(state => state.ichatVariables.activeView);
    const recipients = useSelector(state => state.Ichat.chats);

    const [parent] = useAutoAnimate();
    const [searchParent,enable] = useAutoAnimate();
    const sideBar = useSelector(state => state.ichatVariables.rightSideBar);
    const draftsMessage = useSelector(
        (state) => state.draftsMessage?.draftsMessage
    );

    const {fetchChat} = useIchat();  
    
    const onEventPerformInStaticSideBar = (action, data) => {
        // if (action === "GROUP_VIEW") {
        //   dispatch(chatAction.setCurrentActiveView('GROUP_VIEW'));
        //   setCurrentPage(-1);
        //   setBlockedStyle(false);
        // }
        // dispatch(chatAction.setConversation([]));
      };


    const renderNav = useCallback(() => {

        if(currentActiveView === IVIEWS.INORMAL_VIEW) {
            if(currentActiveChat) {
                return <MiddleNavBar OnSearchOpen={openSearch}/>
            }
            else{
                return <NoRecipientSelected/>
            }
        }
        else if(currentActiveView === IVIEWS.IGROUP_VIEW){
            if(currentActiveGroup) {
                return <GMiddleNavBar OnSearchOpen={openSearch}/> 
            }
            else{
                return <NoRecipientSelected/>
            }
        }

    },[currentActiveChat,currentActiveGroup,currentActiveView]);

    const openSearch = () => {

    }

    useEffect(() => {
        setShowTour(showTour);
    },[showTour]);

    useEffect(() => {
        // Only calling if recipient is not already fetched
        // What are the scenarios that this data can be stale????
        if(recipients.length === 0 && currentActiveView === IVIEWS.INORMAL_VIEW){
            fetchChat();
        }
    },[currentActiveView]);

    useEffect(() => {
        const drafUserFn = async() => {
            if((currentActiveView === IVIEWS.INORMAL_VIEW && currentActiveChat) || 
            (currentActiveView === VIEWS.IGROUP_VIEW && currentActiveGroup)){
                let chatId = currentActiveView === VIEWS.IGROUP_VIEW && currentActiveGroup?.chatId || currentActiveView === IVIEWS.INORMAL_VIEW && currentActiveChat?.chatId;
                if (draftUserInfo?.id !== null && (messageState?.message !== draftsMessage[draftUserInfo?.id]?.message  || messageState?.attachement !== draftsMessage[draftUserInfo?.id]?.attachement) && (draftUserInfo.id !== chatId)){
                 dispatch(
                    draftsMessageAction.setDraftsMessage({
                    ...draftsMessage,
                    [`${draftUserInfo.id}`]: {
                    ...messageState,
                    attachement:messageState?.attachement &&  await getAsDataUrl(messageState.attachement)
                  },
                }));
              }
            }
        }
        drafUserFn();
      }, [draftUserInfo,currentActiveView,currentActiveChat,currentActiveGroup,dispatch,draftsMessage,messageState]);
    return (
      
        <ChatMainWrapper style={{overflow:'hidden'}} isShowAlert={showAlert}>
            {/* <Tour
                steps={steps}
                isOpen={currentShowTour}
                onRequestClose={() => {
                    setShowTour(false);
                    dispatch(ichatVariablesAction.updateTour(false))
                }}
            /> */}
        {/**Render for larger screens Screen ie. above mobile */}
            <MediaQuery minWidth={768}>
              <ChatLeftPanel 
                onMouseDown={async(e) => {  
                    if (!document.querySelector('.draft') || (document.querySelector('.draft') !== null 
                    && !document?.querySelector('.draft')?.contains( e.target))) {
                        if((currentActiveView === IVIEWS.INORMAL_VIEW && currentActiveChat) || 
                        (currentActiveView === VIEWS.IGROUP_VIEW && currentActiveGroup)){
                            let chatId = currentActiveView === VIEWS.IGROUP_VIEW && currentActiveGroup?.chatId || currentActiveView === IVIEWS.INORMAL_VIEW && currentActiveChat?.chatId;
                            if (draftUserInfo?.id !== null &&
                            (messageState?.message !==
                            draftsMessage[chatId]?.message  || messageState?.attachement !==
                            draftsMessage[chatId]?.attachement) 
                        ){
                            dispatch(
                                draftsMessageAction.setDraftsMessage({
                                ...draftsMessage,
                                [`${chatId}`]: {
                                ...messageState,
                                attachement:messageState?.attachement &&  await getAsDataUrl(messageState.attachement)
                            },
                            }));
                        }
                }}}}

                onClick={(e) => {
                  setDraftsUserInfo({
                    m:"",
                    id:null,
                  });
                  if ((currentActiveView === IVIEWS.INORMAL_VIEW && currentActiveChat) || (currentActiveView === VIEWS.IGROUP_VIEW && currentActiveGroup) ) {
                    let selectedUser = document.querySelector(
                      ".hWmHfE .eIYSuk .draft div div div div.idQygM li div span.css-18ym6au-MuiTypography-root"
                    );
                   
                    setDraftsUserInfo({m:selectedUser?.innerText,
                    id: currentActiveView === VIEWS.IGROUP_VIEW && currentActiveGroup?.chatId || currentActiveView === IVIEWS.INORMAL_VIEW && currentActiveChat?.chatId})
                }}}
              >
                    <ILeftChatNavBar/>
                    <ChatSideBar onEventPerform={onEventPerformInStaticSideBar}/>
                    <LeftChatPanel>
                        <RecipientsList recipients={recipients}/>
                    </LeftChatPanel>
                </ChatLeftPanel>
                <MDBox ref={searchParent} display="flex" flexDirection="row" width="100%">
                    <ChatRightPanel style={{width:`${sideBar.open ? "60%" : "100%"}`,borderRight : "1px solid rgb(221, 221, 221)",minWidth:'300px'}}>
                        {renderNav()}
                        <MDBox ref={parent} display="flex" sx={{  overflow: "auto"}} flexDirection="row" width="100%" height={'100%'}>
                            {(currentActiveView === IVIEWS.INORMAL_VIEW) && currentActiveChat && <IConversation key={"iconversation"}/>}
                            {(currentActiveView === IVIEWS.IGROUP_VIEW) && currentActiveGroup && <IGroupConversation key={"igroup_conversation"}/>}
                        </MDBox>  
                        {(currentActiveView === IVIEWS.INORMAL_VIEW) && currentActiveChat && <IChatFooterWrapper key={"nchat_footer"}  messageState={messageState}
                      setMessageState={setMessageState}/>}  
                        {(currentActiveView === IVIEWS.IGROUP_VIEW) && currentActiveGroup && <IGroupChatFooterWrapper key={"gchat_footer"}  messageState={messageState}
                      setMessageState={setMessageState}/>}            
                    </ChatRightPanel>
                    {sideBar?.open && 
                        <MDBox minWidth="40%" maxWidth="40%" height="100%" sx={{backgroundColor : "rgb(255, 255, 255)"}}>
                            {sideBar?.content}
                        </MDBox>
                    }       
                </MDBox>
            </MediaQuery>

        {/**For smaller devicess....*/}
            <MediaQuery maxWidth={768}>
                <ChatLeftPanel>
                    {(!currentActiveChat) && (!currentActiveGroup) && <ILeftChatNavBar/>}
                    {(!currentActiveChat && !currentActiveGroup) && <ChatSideBar onEventPerform={onEventPerformInStaticSideBar}/>}
                    <LeftChatPanel>
                        {(currentActiveChat || currentActiveGroup) ? 
                            <>
                                <ChatRightPanel style={{display:`${sideBar.open ? "none" : "flex"}`,borderRight : "1px solid rgb(221, 221, 221)",minWidth:'300px'}}>
                                {renderNav()}
                                    <MDBox ref={parent} display="flex" flexDirection="column" width="100%" height={'100%'}>
                                        {(currentActiveView === IVIEWS.INORMAL_VIEW) && currentActiveChat && <IConversation key={"iconversation"}/>}
                                        {(currentActiveView === IVIEWS.IGROUP_VIEW) && currentActiveGroup && <IGroupConversation key={"igroup_conversation"}/>}
                                        {(currentActiveView === IVIEWS.INORMAL_VIEW) && currentActiveChat && <IChatFooterWrapper key={"nchat_footer"}  messageState={messageState} setMessageState={setMessageState}/>}  
                                        {(currentActiveView === IVIEWS.IGROUP_VIEW) && currentActiveGroup && <IGroupChatFooterWrapper key={"gchat_footer"}  messageState={messageState} setMessageState={setMessageState}/>}                            
                                    </MDBox> 
                                </ChatRightPanel>
                                    {sideBar?.open && 
                                        <MDBox minWidth="100%" maxWidth="40%" height="100%" sx={{backgroundColor : "rgb(255, 255, 255)"}}>
                                            {sideBar?.content}
                                        </MDBox>
                                    }                                      
                            </>
                        :
                            <RecipientsList recipients={recipients}/>
                        }
                    </LeftChatPanel>
                </ChatLeftPanel>                    
            </MediaQuery>            
        </ChatMainWrapper>
      
    )
}
export default memo(IChat);