import { Box, Icon, Tooltip, Typography } from "@mui/material";
import useHttp from "hooks/use-http";
import MDBox from "lib/components/MDBox";
import MDButton from "lib/components/MDButton";
import MDInput from "lib/components/MDInput";
import MDTypography from "lib/components/MDTypography";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import AddKeyword from "./AddKeyword";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';      
import Swal from "sweetalert2";
import { PhoneAPI } from "services/PhoneAPI";
import { loaderAction } from "store/loader-slice";
import { KeywordSettingsAPI } from "services/KeywordSettingsAPI";

const KeywordBasedResponse = () => {

    const dispatch = useDispatch();

    const [modalOpen,setModalOpen] = useState(false);

    const [keywords, setKeywords] = useState([]);

    const user = useSelector(state => state.user);

    const onKeywordDeleteHandler = useCallback((id) => {
      Swal.fire({
        title: 'Are you sure want to delete?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        customClass: {
          container : {
            zIndex : '10000 !important'
          }
        }
      }).then((result) => {
        if (result.isConfirmed) {
          const keywordToDelete = keywords.find(ky => ky.id === id);
          const filteredArr = keywords.filter(ky => !(ky.id === id));
          dispatch(loaderAction.show());
          if(keywordToDelete?.phone){
            PhoneAPI.removeKeywords({keywordId : keywordToDelete.id,token: user.token}).then(res => {
              dispatch(loaderAction.hide());          
              setKeywords(filteredArr);
              toast.success("Removed successfully.");
            }).catch(e => {
              dispatch(loaderAction.hide());
              toast.success("Something went wrong, Please try again.");
            });
          }
          else{
            KeywordSettingsAPI.removeKeywords({keywordId : keywordToDelete.id,token: user.token}).then(res => {
              dispatch(loaderAction.hide());          
              setKeywords(filteredArr);
              toast.success("Removed successfully.");
            }).catch(e => {
              dispatch(loaderAction.hide());
              toast.success("Something went wrong, Please try again.");
            });
          }
        }
      })
    },[user.token,dispatch,setKeywords,keywords]) 

    const onKeywordAddedHandler = useCallback((data) => {
      const payload = {
        ...data,
        company : {
          company_id : user.company?.company_id
        }
      }
      setModalOpen(false);
      dispatch(loaderAction.show());
      if(payload.payment_associated){
        payload.company = undefined;
        payload.companyId = user.company?.company_id;
        PhoneAPI.savePhoneKeyword({payload,token : user.token}).then(res => {
          dispatch(loaderAction.hide());
          toast.success("Keyword added successfully..");
          setKeywords(prev => [res.data,...prev]);
        }).catch(e => {
          dispatch(loaderAction.hide());
          toast.error("We are not able to add this keyword, make sure it is does not already exists.");
        });
      }else{
        KeywordSettingsAPI.addKeywords({payload,token : user.token}).then(res => {
          dispatch(loaderAction.hide());
          toast.success("Added keyword successfully.");
          setKeywords(prev => [res.data,...prev]);
        }).catch(e => {
          dispatch(loaderAction.hide());
          toast.error("We are not able to add this keyword, make sure it is does not already exists.");
        });
      }
    },[user.company,setKeywords,dispatch,setModalOpen,user.token])
    
   useEffect(() => {
    if(user && user.company) {
      KeywordSettingsAPI.getKeywords({token: user.token,companyId : user.company.company_id}).then(res => {
        const fetchedKeywords = res.data;
        const k1 = fetchedKeywords?.filter(ky => (ky.keyword.toLowerCase() === 'info' || ky.keyword.toLowerCase() === 'help'));
        const k2 = fetchedKeywords?.filter(ky => !(ky.keyword.toLowerCase() === 'info' || ky.keyword.toLowerCase() === 'help'));
        setKeywords(prev => [...prev,...k1,...k2]);
      }).catch(e => {
        console.log(e);
      });
      PhoneAPI.getAllPhoneKeywordForCompany({token: user.token,companyId : user.company.company_id}).then(res => {
        setKeywords(prev => [...res.data,...prev])
      }).catch(e => {
        console.log(e);
      });
    }
   },[user.company, user.token])
   
  return (
    <Box sx={{ width: "100%", p: 2, height: "100%" }}>
      <MDBox display="flex" flexDirection="row" justifyContent="flex-end">
        <MDButton  onClick={() => {setModalOpen(true);}} variant="gradient" color="info">
          <Icon sx={{ fontWeight: "bold" }}>add</Icon>
          &nbsp;Keyword
        </MDButton>
      </MDBox>
      {keywords.length === 0 && <MDBox p={2}>
        <MDTypography
          variant="h6"
          fontWeight="medium"
        >
          No keyword based response set yet.
        </MDTypography>
      </MDBox>}
      {keywords && keywords.length > 0 && 
        keywords.map( (keyword,index) => <MDBox key={index} p={2}>
            <MDTypography
              variant="h6"
              fontWeight="medium"
              textTransform="capitalize"
              >
              {keyword.keyword}
            </MDTypography>
            {keyword?.group && <Typography fontSize={"12px"}>
              {`Group mapped : ${keyword?.group?.name}`}{keyword?.group?.category ? `(${keyword.group.category.name})`: ''}
              </Typography>}
            {Boolean(keyword.payment_associated) && <Typography fontSize={"12px"}>
              {`Mapped with payment for phone: ${keyword?.phone?.phone}`}
              </Typography>}  
            <MDInput
              style={{ width: "60%", margin: "4px" }}
              type="text"
              label=""
              multiline
              disabled={true}
              value={keyword.reply ? keyword?.reply : (keyword?.phone ? '' : keyword?.group?.name)}
            />  
            <Tooltip title="Delete this entry">
              <DeleteForeverIcon onClick={onKeywordDeleteHandler.bind(null,keyword.id)} style={{color:'#a89c9c',cursor:'pointer'}}/>
            </Tooltip>
        </MDBox>)
      }
      {modalOpen && <AddKeyword onClose={() => setModalOpen(false)} onAdded={onKeywordAddedHandler}/>}  
    </Box>
  );
};
export default KeywordBasedResponse;
