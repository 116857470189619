import MDInput from "lib/components/MDInput";
import MDBox from "lib/components/MDBox";

const ProfileInfo = ({profileData,setProfileData,profileValidation,setProfileValidation}) => {

    return(
      <MDBox>
        <MDBox>
          <MDBox mb={2} display="flex">
            <MDBox mr={1} width="50%">
              <MDInput
                error={profileValidation?.firstName}
                value={profileData.firstName}
                onChange={(e) => {
                    setProfileData((prev) => {
                      return {
                        ...prev,
                        firstName: e.target.value,
                      };
                    });
                    setProfileValidation(prev => {
                      return{
                        ...prev,
                        firstName: false
                      }
                    })
                  }
                }
                type="text"
                label="First Name"
                variant="standard"
                fullWidth
              />
            </MDBox>
            <MDBox ml={1} width="50%">
              <MDInput
                error={profileValidation?.lastName}
                value={profileData?.lastName}
                onChange={(e) =>{
                  setProfileData((prev) => {
                    return {
                      ...prev,
                      lastName: e.target.value,
                    };
                  })
                  setProfileValidation(prev => {
                    return{
                      ...prev,
                      lastName : false
                    }
                  })
                }
                }
                type="text"
                label="Last Name"
                variant="standard"
                fullWidth
              />
            </MDBox>
          </MDBox>

          <MDBox mb={2}>
            <MDInput
              error={profileValidation?.companyName}
              value={profileData.companyName}
              onChange={(e) => {
                  setProfileData((prev) => {
                    return {
                      ...prev,
                      companyName: e.target.value,
                    };
                  })
                  setProfileValidation(prev => {
                    return{
                      ...prev,
                      companyName : false
                    }
                  })
                }
              }
              type="text"
              label="Company Name"
              variant="standard"
              fullWidth
            />
          </MDBox>

          <MDBox mb={2}>
            <MDInput
              type="email"
              value={profileData.email}
              error={profileValidation?.email}
              onChange={(e) => {
                  setProfileData((prev) => {
                    return {
                      ...prev,
                      email: e.target.value,
                    };
                  })
                  setProfileValidation(prev => {
                    return{
                      ...prev,
                      email : false
                    }
                  })
                }
              }
              label="Email"
              variant="standard"
              fullWidth
            />
          </MDBox>

          <MDBox mb={2}>
            <MDInput
              type="number"
              value={profileData.contactNumber}
              error={profileValidation?.contactNumber}
              onChange={(e) => {
                setProfileData((prev) => {
                  return {
                    ...prev,
                    contactNumber: e.target.value,
                  };
                })
                setProfileValidation(prev => {
                  return{
                    ...prev,
                    contactNumber : false
                  }
                })
              }
              }
              label="Contact Number"
              variant="standard"
              fullWidth
            />
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              type="text"
              value={profileData.address}
              error={profileValidation?.address}
              onChange={(e) => {
                  setProfileData((prev) => {
                    return {
                      ...prev,
                      address: e.target.value,
                    };
                  })
                  setProfileValidation(prev => {
                    return{
                      ...prev,
                      address : false
                    }
                  })
                }
              }
              label="Address"
              variant="standard"
              fullWidth
            />
          </MDBox>

          <MDBox mb={2}>
            <MDInput
              value={profileData.address1}
              type="text"
              onChange={(e) =>
                setProfileData((prev) => {
                  return {
                    ...prev,
                    address1: e.target.value,
                  };
                })
              }
              label="Address2 (optional)"
              variant="standard"
              fullWidth
            />
          </MDBox>
          
          <MDBox mb={2} display="flex" flexDirection="row">

            <MDBox mr={1}>
              <MDInput
                type="text"
                error={profileValidation?.city}
                value={profileData.city}
                onChange={(e) => {
                    setProfileData((prev) => {
                      return {
                        ...prev,
                        city: e.target.value,
                      };
                    })
                    setProfileValidation(prev => {
                      return{
                        ...prev,
                        city : false
                      }
                    })
                  }
                }
                label="City"
                variant="standard"
                fullWidth
              />
            </MDBox>

            <MDBox ml={1}>
              <MDInput
                type="text"
                error={profileValidation?.state}
                value={profileData.state}
                onChange={(e) => {
                    setProfileData((prev) => {
                      return {
                        ...prev,
                        state: e.target.value,
                      };
                    })
                    setProfileValidation(prev => {
                      return{
                        ...prev,
                        state : false
                      }
                    })
                  }
                }
                label="State"
                variant="standard"
                fullWidth
              />
            </MDBox>

            <MDBox ml={2}>
              <MDInput
                type="number"
                error={profileValidation?.zipcode}
                value={profileData.zipcode}
                onChange={(e) => {
                    setProfileData((prev) => {
                      return {
                        ...prev,
                        zipcode: e.target.value,
                      };
                    })
                    setProfileValidation(prev => {
                      return{
                        ...prev,
                        zipcode : false
                      }
                    })                  
                  }
                }
                label="Zipcode"
                variant="standard"
                fullWidth
              />
            </MDBox>

          </MDBox>

        </MDBox>
      </MDBox>
    )
}
export default ProfileInfo;